'use client'

import { File } from '@repo/ui/icons'
import Image from 'next/image'

import useMapStore from '@/common/store/map'
import useSentinelOption from '@/common/store/sentinel-option'
import { getFirstFrameAndTrack } from '@/utils/common'

const DetailedImage = () => {
  const { selectedImages } = useMapStore()
  const { selectedFirstFrame, selectedTrack } = useSentinelOption()

  const selectedImagesByFrame =
    selectedImages[
      getFirstFrameAndTrack(selectedFirstFrame || '0', selectedTrack || '0')
    ] || []

  const detailedImageData =
    selectedImagesByFrame[selectedImagesByFrame.length - 1]

  // console.log(detailedImageData);

  const imageSrc = detailedImageData?.browse
    ? `${process.env.NEXT_PUBLIC_MAIN_SERVER_DOMAIN}/api/satellites/search/cache/get/image?key=${detailedImageData?.browse}`
    : '/assets/logo.webp'

  const isLogo = imageSrc === '/assets/logo.webp'
  const logoWidth = 200 // ? 로고의 너비
  const logoHeight = 200 // ? 로고의 높이

  return (
    <div className="flex h-[384px] flex-col items-end">
      <div className="flex w-full items-center gap-2 border-y-DEFAULT py-2">
        <File />
        <span className="font-semibold">Detailed Image Information</span>
      </div>

      <div className="custom-scrollbar flex w-full flex-col items-center gap-2 overflow-auto bg-gray-100 p-4 text-[12px]">
        <Image
          src={detailedImageData?.browse || imageSrc}
          alt="Detailed Image"
          width={isLogo ? logoWidth : 400} // ? 로고일 경우와 아닐 경우의 너비 설정
          height={isLogo ? logoHeight : 200} // ? 로고일 경우와 아닐 경우의 높이 설정
          className={`rounded-lg border bg-gray-300 ${isLogo && 'p-16'}`}
          key={detailedImageData?.browse} // ? 이미지가 바뀔 때마다 key를 변경하여 리액트가 새 이미지로 인식하도록 함
        />
        {detailedImageData ? (
          <div className="flex w-full justify-items-start gap-2">
            <span className="w-20 font-bold">Title</span>
            <span className="line-clamp-2 flex-1 overflow-hidden whitespace-normal break-words">
              {detailedImageData?.title}
            </span>
          </div>
        ) : (
          <span className="text-[16px] font-semibold">Select your image</span>
        )}
        <div className="flex w-full gap-2">
          <div className="flex flex-col gap-1">
            {detailedImageData?.tags?.map((tagObj, index) => {
              const [key, value] = Object.entries(tagObj)[0] // 첫 번째 키-값 쌍을 가져옵니다.

              return (
                <span
                  key={index}
                  className="flex gap-4 rounded-md py-1 text-xs font-medium"
                >
                  <span className="w-28 font-bold">
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </span>
                  <span>{value}</span>
                </span>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailedImage
