// hooks/useImageCache.ts
import { imageCache } from '@repo/common/lib/image-cache'
import { useEffect, useState } from 'react'

interface CacheResult {
  objectUrl: string | null
  loading: boolean
  error: string | null
}

export function useImageCache(imageUrl: string): CacheResult {
  const [objectUrl, setObjectUrl] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    let mounted = true

    const loadImage = async () => {
      if (!imageUrl) return

      try {
        setLoading(true)
        setError(null)

        const cachedUrl = await imageCache.cacheImage(imageUrl)

        if (mounted) {
          setObjectUrl(cachedUrl)
          setLoading(false)
        }
      } catch (err) {
        if (mounted) {
          setError(err instanceof Error ? err.message : 'Failed to load image')
          setLoading(false)
        }
      }
    }

    loadImage()

    return () => {
      mounted = false
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl)
      }
    }
  }, [imageUrl])

  return { objectUrl, loading, error }
}
