'use client'

import { ProcessState } from '@repo/common/dto'
import { ApiResponse } from '@repo/common/interfaces'
import { AnimatePresence, motion } from 'framer-motion'
import Cookies from 'js-cookie'
import { useSearchParams } from 'next/navigation'
import React, { useState } from 'react'

import { ToggleImage } from '@/app/(main)/(home)/searched-data/(strategy)/interfaces/searched-data.interface'
import { SearchedDataService } from '@/app/(main)/(home)/searched-data/(strategy)/services/searched-data.service'

import ProcessPopupBackground from './components/ProcessPopupBackground'
import ProcessPopupContainer from './components/ProcessPopupContainer'
import ProcessPopupFirstPhase from './components/ProcessPopupFirstPhase'
import ProcessPopupHeader from './components/ProcessPopupHeader'
import ProcessPopupLastPhase from './components/ProcessPopupLastPhase'
import ProcessPopupMap from './components/ProcessPopupMap'
import ProcessPopupSecondPhase from './components/ProcessPopupSecondPhase'
import ProcessStatusBar from './components/ProcessStatusBar'
import useMapStore from '@/common/store/map'
import useProcessStore from '@/common/store/process'
import useSentinelOption from '@/common/store/sentinel-option'
import { getFirstFrameAndTrack } from '@/utils/common'

const fadeVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

const ProcessPopup = () => {
  const searchParams = useSearchParams()

  const {
    selectedImages: globalSelectedImages,
    isProcessROIEnabled,
    setIsProcessROIEnable: setProcessROIEnable,
    roiArea,
    setRoiArea,
    roiCost,
    setRoiCost,
  } = useMapStore()

  const { openProcessPopup, setOpenProcessPopup, currentPage, setCurrentPage } =
    useProcessStore()

  const { selectedFirstFrame, selectedTrack } = useSentinelOption()

  const [selectedImages, setSelectedImages] = useState<ToggleImage[]>([])

  if (!selectedFirstFrame || !selectedTrack) {
    return null
  }

  const currentFrameAndTrackImages: ToggleImage[] =
    globalSelectedImages[
      getFirstFrameAndTrack(selectedFirstFrame, selectedTrack)
    ]
  // console.log("currentFrameAndTrackImages", currentFrameAndTrackImages);

  const accessToken = Cookies.get('accessToken')
  const searchedDataService = new SearchedDataService(accessToken)

  const executeDInSARProcess: (
    userId: string,
    categoryId: string
  ) => Promise<ApiResponse<ProcessState>> = async (
    userId: string,
    categoryId: string
  ) => {
    try {
      return await searchedDataService.executeDInSARProcess(
        currentFrameAndTrackImages,
        userId,
        categoryId
      )
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(
          'ProcessPopup:Error executing DInSAR process:',
          error.message
        )
      } else {
        console.error(
          'ProcessPopup:Error executing DInSAR process:',
          String(error)
        )
      }
      throw error
    }
  }

  // 이미지 토글 기능
  // const toggleImageSelection = async (imageTitle: string) => {
  //   try {
  //     // ? 이미지 선택 토글
  //     setSelectedImages((prevSelectedImages: ToggleImage[]) => {
  //       const isImageSelected = prevSelectedImages.some(
  //         (image: ToggleImage) => image.title === imageTitle
  //       )
  //       if (isImageSelected) {
  //         // 이미 선택된 이미지 제거
  //         console.log('prevSelectedImages', prevSelectedImages, imageTitle)
  //         return prevSelectedImages.filter(
  //           (image: ToggleImage) => image.title !== imageTitle
  //         )
  //       } else {
  //         // 이미지 추가
  //         const imageAdded = globalSelectedImages[
  //           getFirstFrameAndTrack(selectedFirstFrame, selectedTrack)
  //         ].find((image: ToggleImage) => image.title === imageTitle)

  //         if (!imageAdded) {
  //           return prevSelectedImages
  //         }

  //         return [...prevSelectedImages, imageAdded]
  //       }
  //     })
  //   } catch (error) {
  //     console.error('ProcessPopup:Error toggling image selection:', error)
  //   }
  // }

  return (
    <>
      {openProcessPopup && (
        <>
          <ProcessPopupBackground />
          <ProcessPopupContainer>
            <ProcessPopupHeader />
            <AnimatePresence mode="wait">
              {currentPage !== 2 && (
                <motion.div
                  key="map"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={fadeVariants}
                  transition={{ duration: 0.3 }}
                  className="h-[400px]"
                >
                  <ProcessPopupMap />
                </motion.div>
              )}
            </AnimatePresence>
            <ProcessStatusBar currentPage={currentPage} />
            <div className="mt-6 flex-1 h-[calc(100%-600px)]">
              <AnimatePresence mode="wait">
                {currentPage === 0 ? (
                  <motion.div
                    key="first-phase"
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={fadeVariants}
                    transition={{ duration: 0.3 }}
                    className="h-full"
                  >
                    <ProcessPopupFirstPhase
                      roiArea={roiArea}
                      roiCost={roiCost}
                      setCurrentPage={setCurrentPage}
                      isProcessROIEnabled={isProcessROIEnabled}
                      setProcessROIEnable={setProcessROIEnable}
                    />
                  </motion.div>
                ) : currentPage === 1 ? (
                  <motion.div
                    key="second-phase"
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={fadeVariants}
                    transition={{ duration: 0.3 }}
                    className="h-full"
                  >
                    <ProcessPopupSecondPhase
                      setCurrentPage={setCurrentPage}
                      setOpenProcessPopup={setOpenProcessPopup}
                      executeDInSARProcess={executeDInSARProcess}
                    />
                  </motion.div>
                ) : (
                  <motion.div
                    key="last-phase"
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={fadeVariants}
                    transition={{ duration: 0.3 }}
                    className="h-[calc(100%+400px)]"
                  >
                    <ProcessPopupLastPhase
                      setCurrentPage={setCurrentPage}
                      setOpenProcessPopup={setOpenProcessPopup}
                      executeDInSARProcess={executeDInSARProcess}
                      currentFrameAndTrackImages={currentFrameAndTrackImages}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </ProcessPopupContainer>
        </>
      )}
    </>
  )
}

export default ProcessPopup
