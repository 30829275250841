// lib/imageCache.ts
class ImageCache {
  private db: IDBDatabase | null = null
  private readonly DB_NAME = 'ImageCacheDB'
  private readonly STORE_NAME = 'images'
  private readonly VERSION = 1

  constructor() {
    this.initDB()
  }

  private initDB(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.DB_NAME, this.VERSION)

      request.onerror = () => {
        reject(request.error)
      }

      request.onsuccess = () => {
        this.db = request.result
        resolve()
      }

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result
        if (!db.objectStoreNames.contains(this.STORE_NAME)) {
          db.createObjectStore(this.STORE_NAME, { keyPath: 'url' })
        }
      }
    })
  }

  async cacheImage(url: string): Promise<string> {
    if (!this.db) {
      await this.initDB()
    }

    // 캐시된 이미지 확인
    try {
      const cachedImage = await this.getCachedImage(url)
      if (cachedImage) {
        return URL.createObjectURL(cachedImage.blob)
      }
    } catch (error) {
      console.warn('Cache check failed:', error)
    }

    // 새로운 이미지 가져오기 및 캐싱
    try {
      const response = await fetch(url)
      const blob = await response.blob()

      const transaction = this.db!.transaction(this.STORE_NAME, 'readwrite')
      const store = transaction.objectStore(this.STORE_NAME)

      await new Promise((resolve, reject) => {
        const request = store.put({
          url,
          blob,
          timestamp: Date.now(),
        })

        request.onsuccess = () => resolve(request.result)
        request.onerror = () => reject(request.error)
      })

      return URL.createObjectURL(blob)
    } catch (error) {
      console.error('Failed to cache image:', error)
      throw error
    }
  }

  private getCachedImage(
    url: string
  ): Promise<{ blob: Blob; timestamp: number } | null> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        resolve(null)
        return
      }

      const transaction = this.db.transaction(this.STORE_NAME, 'readonly')
      const store = transaction.objectStore(this.STORE_NAME)
      const request = store.get(url)

      request.onsuccess = () => {
        resolve(request.result)
      }

      request.onerror = () => {
        reject(request.error)
      }
    })
  }

  async clearOldCache(maxAge: number = 7 * 24 * 60 * 60 * 1000) {
    // 기본 7일
    if (!this.db) {
      await this.initDB()
    }

    const now = Date.now()
    const transaction = this.db!.transaction(this.STORE_NAME, 'readwrite')
    const store = transaction.objectStore(this.STORE_NAME)
    const request = store.openCursor()

    request.onsuccess = (event) => {
      const cursor = (event.target as IDBRequest).result
      if (cursor) {
        if (now - cursor.value.timestamp > maxAge) {
          store.delete(cursor.key)
        }
        cursor.continue()
      }
    }
  }
}

export const imageCache = new ImageCache()
