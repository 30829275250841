'use client'

import { MAP_TILES } from '@repo/common/constants'
import { IExtendedLeafletParameters } from '@repo/common/interfaces'
import { leafletService } from '@repo/common/services'
import * as L from 'leaflet'
import { useSearchParams } from 'next/navigation'
import React, { useEffect, useRef } from 'react'

import { mapParams } from '@/app/(main)/hooks/useMap'

import ProcessPopupMapROI from './ProcessPopupMapROI'
import useMapStore from '@/common/store/map'
import useSentinelOption from '@/common/store/sentinel-option'
import { getFirstFrameAndTrack } from '@/utils/common'
import { getPolygonCoords } from '@/utils/leaflet'

const ProcessPopupMap = () => {
  const mapRef = useRef<L.Map | null>(null)
  const searchParams = useSearchParams()
  const {
    mapCenter,
    currentTileLayer,
    selectedImages: globalSelectedImages,
  } = useMapStore()

  const { selectedFirstFrame, selectedTrack } = useSentinelOption()

  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = L.map('map2', {
        ...mapParams,
        center: mapCenter,
        zoomControl: false,
        boxZoom: false,
        touchZoom: false,
      })
      const tileLayer = MAP_TILES.find(
        (tile) => tile.layerName === currentTileLayer
      )
      if (tileLayer) {
        L.tileLayer(tileLayer.url, tileLayer.options).addTo(mapRef.current)
      }
    }

    // 이미지 오버레이와 폴리곤을 맵에 추가하기 위한 로직
    const overlays: L.Layer[] = []

    if (!mapRef.current || !selectedFirstFrame || !selectedTrack) {
      return
    }

    const currentMap = mapRef.current
    // 사용자 정의 pane 생성

    currentMap.createPane('polygonPane')
    currentMap.getPane('polygonPane')?.style.setProperty('zIndex', '450')

    // 이미지 경계를 기반으로 지도의 뷰를 설정하는 로직
    const leafletParams: IExtendedLeafletParameters = {
      firstFrame: selectedFirstFrame,
      track: selectedTrack,
      selectedImages: globalSelectedImages,
      map: currentMap,
    }

    leafletService.setViewByImageBounds(leafletParams)

    globalSelectedImages[
      getFirstFrameAndTrack(selectedFirstFrame, selectedTrack)
    ]
      .map((image) => image.polygon)
      .forEach((image) => {
        const polygonCoords = getPolygonCoords(image)

        // ? 폴리곤 추가
        const polygon = L.polygon(polygonCoords, {
          color: 'orange',
          fillOpacity: 0.2,
          pane: 'polygonPane',
        }).addTo(currentMap)
        overlays.push(polygon)
      })

    // 맵에서 오버레이 제거
    return () => {
      overlays.forEach((overlay) => mapRef.current?.removeLayer(overlay))
    }
  }, [
    mapCenter,
    currentTileLayer,
    globalSelectedImages,
    searchParams,
    leafletService,
  ])

  return (
    <div className="relative h-full w-full">
      <ProcessPopupMapROI currentMap={mapRef.current} />
      <div
        id="map2"
        className="absolute bottom-0 left-0 z-20 mx-auto size-full rounded-lg shadow-xl"
      />
    </div>
  )
}

export default ProcessPopupMap
