import React from "react";
import { DesktopAnalytics } from "@repo/ui/icons";
import ProcessPopupHeaderCloseBtn from "./ProcessPopupHeaderCloseBtn";

const currentProcessName = "D-InSAR";

const ProcessPopupHeader = () => {
  return (
    <div className="mb-2 flex items-center justify-between">
      <div className="flex items-center justify-center gap-2">
        <DesktopAnalytics />
        <span className="font-semibold">Process Execution Popup</span>
        <span className="text-[14px] font-medium text-primary-500">
          ({currentProcessName})
        </span>
      </div>
      <ProcessPopupHeaderCloseBtn />
    </div>
  );
};

export default ProcessPopupHeader;
