import { MAP_PARAMS, MAP_TILES } from '@repo/common/constants'
import * as L from 'leaflet'
import { useSearchParams } from 'next/navigation'
import { useEffect, useRef } from 'react'

import useMapStore from '@/common/store/map'

export const mapParams: L.MapOptions = {
  center: MAP_PARAMS.center,
  zoom: MAP_PARAMS.zoom,
  zoomControl: MAP_PARAMS.zoomControl,
  maxBounds: L.latLngBounds(L.latLng(-120, -270), L.latLng(120, 270)), // 지도 이동 범위 제한
  minZoom: 3, // 최소 줌 레벨을 3으로 설정 (원하는 레벨로 조정 가능)
}

export function useMap() {
  const mapRef = useRef<L.Map | null>(null)
  const tileLayerRef = useRef<L.TileLayer | null>(null)
  const {
    currentTileLayer,
    currentMap,
    setCurrentMap,
    mapCenter,
    setMapCenter: setCurrentMapCenter,
    setROIEnable,
  } = useMapStore()

  const searchParams = useSearchParams()

  useEffect(() => {
    if (
      searchParams.has('lowerRightLat') &&
      searchParams.has('lowerRightLng') &&
      searchParams.has('upperLeftLat') &&
      searchParams.has('upperLeftLng')
    ) {
      setROIEnable(false)
    }
  }, [searchParams, setROIEnable])

  useEffect(() => {
    mapRef.current = L.map('map', { ...mapParams, center: mapCenter })

    mapRef.current.on('moveend', () => {
      if (!mapRef.current) {
        return
      }

      const center = mapRef.current.getCenter()
      setCurrentMapCenter([center.lat, center.lng])
    })

    if (mapRef.current) {
      MAP_TILES.map((map) => {
        if (map.layerName === currentTileLayer) {
          tileLayerRef.current = L.tileLayer(map.url, map.options).addTo(
            mapRef.current as L.Map
          )
        }

        return null
      })
    }

    return () => {
      mapRef.current?.remove()
    }
    // eslint-disable-next-line
  }, [mapRef])

  useEffect(() => {
    if (tileLayerRef.current) {
      tileLayerRef.current.remove()
    }

    if (mapRef.current) {
      MAP_TILES.map((map) => {
        if (map.layerName === currentTileLayer) {
          tileLayerRef.current = L.tileLayer(map.url, map.options).addTo(
            mapRef.current as L.Map
          )
        }

        return null
      })
    }
  }, [currentTileLayer, currentMap])

  useEffect(() => {
    setCurrentMap(mapRef.current)
  }, [setCurrentMap])

  return { currentMap }
}
