'use client'

import * as L from 'leaflet'
import { useEffect, useRef, useState } from 'react'

import useMapStore from '@/common/store/map'
import { calculateAreaKM2, calculateCostByKM2 } from '@/utils/leaflet'
import {
  drawRectangle,
  startDrawingRectangle,
  stopDrawingRectangle,
} from '@/utils/roi'

const ProcessPopupMapROI = ({ currentMap }) => {
  const {
    isProcessROIEnabled,
    setIsProcessROIEnable: setProcessROIEnable,
    setRoiArea,
    setRoiCost,
  } = useMapStore()

  // ? ROI를 그리기 위한 canvas와 context
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const contextRef = useRef<CanvasRenderingContext2D | null>(null)

  // ? requestAnimationFrame을 위한 requestId
  const requestIdRef = useRef(0)

  const canvasOffSetX = useRef(0)
  const canvasOffSetY = useRef(0)
  const startX = useRef(0)
  const startY = useRef(0)

  const [roiPoints, setRoiPoints] = useState<{
    leftTop: L.LatLng | null
    rightBottom: L.LatLng | null
  } | null>(null)

  const [isDrawing, setIsDrawing] = useState(false)

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas) {
      canvas.width = 720
      canvas.height = 384

      const context = canvas.getContext('2d')
      if (context) {
        context.lineCap = 'round'
        context.strokeStyle = 'dodgerblue'
        context.lineWidth = 1
        contextRef.current = context
      }
    }

    return () => {
      if (canvas) {
        // console.log(canvas);
        canvas.width = 0
        canvas.height = 0
      }
      if (requestIdRef.current) {
        cancelAnimationFrame(requestIdRef.current)
      }
    }
  }, [requestIdRef])

  // ? 마우스가 mouseup 되었을 때, 그리기를 멈추고, 그린 ROI를 쿼리스트링으로 업데이트
  useEffect(() => {
    const handleMouseUp = () => {
      if (!isDrawing) {
        return
      }

      try {
        // console.log("start");
      } catch (error) {
        console.error('ProcessPopupMapROI:Error updating query string:', error)
      } finally {
        setIsDrawing(false)

        if (roiPoints && roiPoints.leftTop && roiPoints.rightBottom) {
          const areaToKm2 = calculateAreaKM2(roiPoints)
          setRoiArea(areaToKm2)
          setRoiCost(calculateCostByKM2(areaToKm2))
          setProcessROIEnable(false)
        }
      }
    }

    window.addEventListener('mouseup', handleMouseUp)

    return () => {
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDrawing, roiPoints, currentMap])

  useEffect(() => {
    if (!isProcessROIEnabled) {
      return
    }
    const updateCanvasOffset = () => {
      requestAnimationFrame(() => {
        if (canvasRef.current) {
          const canvas = canvasRef.current
          const canvasOffSet = canvasRef.current.getBoundingClientRect()

          canvas.width = canvas.parentElement!.clientWidth
          canvas.height = canvas.parentElement!.clientHeight
          canvasOffSetX.current = canvasOffSet.left
          canvasOffSetY.current = canvasOffSet.top
        }
      })
    }

    updateCanvasOffset()
    window.addEventListener('resize', updateCanvasOffset)

    return () => {
      window.removeEventListener('resize', updateCanvasOffset)
    }
  }, [isProcessROIEnabled])

  return (
    <div
      className={`absolute bottom-0 left-0 z-50 size-full ${isProcessROIEnabled ? 'block' : 'hidden'}`}
    >
      <canvas
        ref={canvasRef}
        className="z-50"
        onMouseDown={(e) =>
          startDrawingRectangle(
            e.nativeEvent,
            currentMap,
            canvasOffSetX,
            canvasOffSetY,
            startX,
            startY,
            setIsDrawing
          )
        }
        onMouseMove={(e) =>
          drawRectangle(
            e,
            isDrawing,
            currentMap,
            canvasOffSetX,
            canvasOffSetY,
            startX,
            startY,
            contextRef,
            canvasRef,
            setRoiPoints,
            requestIdRef
          )
        }
        onMouseUp={() => stopDrawingRectangle(contextRef, canvasRef)}
        onMouseLeave={() => stopDrawingRectangle(contextRef, canvasRef)}
        data-testid="process-canvas"
      ></canvas>
    </div>
  )
}

export default ProcessPopupMapROI
