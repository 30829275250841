import { ISelectionSidebarService } from "../interface/SelectionSidebar.params";
import { SelectionSidebarPersistance } from "../repository/SelectionSidebar.repository";

export class SelectionSidebarService implements ISelectionSidebarService {
  persistance: SelectionSidebarPersistance;

  constructor() {
    this.persistance = new SelectionSidebarPersistance();
  }
}
