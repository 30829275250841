import {
  ICalculatedBox,
  // IExtendedSearchParam,
  ISearchParam,
} from "!/interfaces/request.interface";

export class SearchParamDto {
  upperLeftLat?: string;
  lowerRightLat?: string;
  upperLeftLng?: string;
  lowerRightLng?: string;
  startDate?: string;
  endDate?: string;
  beamMode?: string;
  polarization?: string;
  flightDirection?: string;
  pageSize?: string;
  page?: string;
  firstFrame?: string;
  track?: string;

  makeSearchParamDtoWithCalculatedBoxAndSearchParams(
    calculatedBox?: ICalculatedBox,
    searchParams?: ISearchParam,
    pageSize?: number,
    page?: number
  ): string {
    const newDto = new SearchParamDto();

    if (calculatedBox) {
      newDto.upperLeftLat = calculatedBox.upperLeftLat.toString();
      newDto.lowerRightLat = calculatedBox.lowerRightLat.toString();
      newDto.upperLeftLng = calculatedBox.upperLeftLng.toString();
      newDto.lowerRightLng = calculatedBox.lowerRightLng.toString();
    }

    if (searchParams) {
      const { firstFrame, track, ...filteredSearchParams } = searchParams;
      // console.log("searchParam.dto", firstFrame, track, filteredSearchParams);

      // startDate, endDate, beamMode, polarization, flightDirection가 있을 경우에만 적용
      newDto.startDate = filteredSearchParams.startDate;
      newDto.endDate = filteredSearchParams.endDate;
      newDto.beamMode = filteredSearchParams.beamMode;
      newDto.polarization = filteredSearchParams.polarization?.replace(
        /\s+/g,
        ""
      );
      newDto.flightDirection = filteredSearchParams.flightDirection;
    }

    // TODO: ROI 범위에 따라 pageSize를 조절하는 로직이 필요.
    newDto.pageSize = pageSize?.toString() || "1000";
    newDto.page = page?.toString() || "1";

    return new URLSearchParams({ ...newDto }).toString();
  }
}
