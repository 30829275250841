import { DInSARImage } from '../dto/searched-data.dto'
// import { DInSARImage } from "@/app/(main)/(home)/searched-data/dto/searched-data.dto";
import { ProcessState } from '@repo/common/dto'
import fetchAPI from '@repo/common/helpers/fetch'
import { ApiResponse } from '@repo/common/interfaces'

export class SearchedDataPersistance {
  BASE_URL = `${process.env.NEXT_PUBLIC_MAIN_SERVER_DOMAIN}`

  constructor(private readonly accessToken: string | undefined) {}

  async executeDInSARProcess(
    master: DInSARImage,
    slave: DInSARImage,
    userId: string,
    categoryId: string
  ) {
    try {
      const response = await fetchAPI<ApiResponse<ProcessState>>(
        `${this.BASE_URL}/api/process/insar`,
        {
          method: 'POST',
          body: JSON.stringify({
            master,
            slave,
            user_id: userId,
            categoryId,
          }),
        },
        this.accessToken
      )
      // console.log(response)
      if (response.status === 201) {
        return response
      }

      throw new Error(`Unexpected response status: ${response.status}`)
    } catch (error) {
      console.error('SearchedDataPersistance:', error.message, error)
      throw error
    }
  }
}
