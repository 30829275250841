import { AnimatePresence, motion } from 'framer-motion'
import { Check, X } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const HighlightOverlay = ({
  position,
  animate,
}: {
  position: {
    top: number
    left: number
    width: number
    height: number
  } | null
  animate: boolean
}) =>
  position && (
    <>
      {/* 하이라이트된 부분을 위한 투명한 구멍 */}
      <motion.div
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.3,
          type: 'spring',
          stiffness: 300,
          damping: 30,
        }}
        className="fixed"
        style={{
          top: position.top - position.height / 2 - 8,
          left: position.left - position.width / 2 - 8,
          width: position.width + 16,
          height: position.height + 16,
          borderRadius: '8px',
          backgroundColor: 'transparent',
          boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.5)',
          zIndex: 102,
        }}
      />

      {/* 하이라이트 테두리 */}
      <motion.div
        layout
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{
          duration: 0.3,
          type: 'spring',
          stiffness: 300,
          damping: 30,
        }}
        className={`fixed rounded-lg ${animate ? 'animate-pulse' : ''}`}
        style={{
          top: position.top - position.height / 2 - 8,
          left: position.left - position.width / 2 - 8,
          width: position.width + 16,
          height: position.height + 16,
          border: '2px solid #7C3AED',
          boxShadow: '0 0 0 4px rgba(124, 58, 237, 0.3)',
          zIndex: 103,
          pointerEvents: 'none',
        }}
      />
    </>
  )

const RequestFieldsOnboarding = ({
  onComplete,
}: {
  onComplete: () => void
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [isVisible, setIsVisible] = useState(true)
  const [isClosing, setIsClosing] = useState(false)
  const [highlightPosition, setHighlightPosition] = useState<{
    top: number
    left: number
    width: number
    height: number
  } | null>(null)

  const steps = [
    {
      title: 'Welcome to SARDIP Web GIS Platform! 🛰️',
      content: 'Let me guide you through our search options.',
      selector: null,
    },
    {
      title: 'Temporal Selection',
      content:
        'Select the time range for your satellite data search using our calendar interface.',
      selector: '[data-highlight="temporal"]',
    },

    {
      title: 'Search Type',
      content:
        'Choose which satellite data source you want to search from \n(Sentinel-1A, Dataset, or Umbra).',
      selector: '[data-highlight="search-type"]',
    },
    {
      title: 'Page Size',
      content:
        'Define how many results you want to see per page in your search results.',
      selector: '[data-highlight="page-size"]',
    },
    {
      title: 'Beam Mode',
      content: 'Select the radar beam mode for your satellite data collection.',
      selector: '[data-highlight="beam-mode"]',
    },
    {
      title: 'Polarization',
      content: 'Choose the polarization type for your radar data.',
      selector: '[data-highlight="polarization"]',
    },
    {
      title: 'Flight Direction',
      content:
        "Specify the satellite's flight direction \n(Ascending or Descending).",
      selector: '[data-highlight="flight-direction"]',
    },
    {
      title: 'Search Data',
      content:
        'Click the search button, then press and hold to drag on the map to select your ROI.',
      selector: '[data-highlight="search-data"]',
    },
    {
      title: 'Timeline',
      content:
        'You can also use our timeline to select the time range for your satellite data search.',
      selector: '[data-highlight="timeline"]',
    },
    {
      title: 'Map Tile Layers',
      content: 'Select the map tile layers you want to see.',
      selector: '[data-highlight="map-tile-layers"]',
    },
    {
      title: 'Ready to Search! 🚀',
      content:
        'You now know all the search options. Start exploring satellite data!',
      selector: null,
    },
  ]

  const isHighlightStep = (stepIndex: number) => {
    return steps[stepIndex].selector !== null
  }

  const updateHighlightPosition = () => {
    const currentStepData = steps[currentStep]
    if (currentStepData.selector) {
      const element = document.querySelector(currentStepData.selector)
      if (element) {
        const rect = element.getBoundingClientRect()
        setHighlightPosition({
          top: rect.top + rect.height / 2,
          left: rect.left + rect.width / 2,
          width: rect.width,
          height: rect.height,
        })
      } else {
        setHighlightPosition(null)
      }
    } else {
      setHighlightPosition(null)
    }
  }

  useEffect(() => {
    updateHighlightPosition()
    const handleUpdate = () => {
      requestAnimationFrame(updateHighlightPosition)
    }

    window.addEventListener('scroll', handleUpdate)
    window.addEventListener('resize', handleUpdate)

    return () => {
      window.removeEventListener('scroll', handleUpdate)
      window.removeEventListener('resize', handleUpdate)
    }
  }, [currentStep])

  const handleClose = () => {
    setIsClosing(true)
    setTimeout(() => {
      setIsVisible(false)
      onComplete()
    }, 300)
  }

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1)
    } else {
      handleClose()
    }
  }

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  return (
    <>
      {/* 배경 오버레이 (하이라이트 스텝이 아닐 때만 표시) */}
      <AnimatePresence>
        {isVisible && !isHighlightStep(currentStep) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black"
            style={{ zIndex: 100 }}
          />
        )}
      </AnimatePresence>

      {/* 하이라이트 오버레이 */}
      <AnimatePresence>
        {isVisible && isHighlightStep(currentStep) && highlightPosition && (
          <HighlightOverlay position={highlightPosition} animate={!isClosing} />
        )}
      </AnimatePresence>

      {/* 가이드 다이얼로그 */}
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 flex items-end justify-end p-4 pointer-events-none"
            style={{ zIndex: 103 }}
          >
            <motion.div
              variants={{
                initial: { opacity: 0, scale: 0.8, y: 20 },
                animate: { opacity: 1, scale: 1, y: 0 },
                exit: { opacity: 0, scale: 0.8, y: 20 },
              }}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.3 }}
              className="bg-white rounded-lg shadow-lg p-6 w-[500px] pointer-events-auto"
            >
              <div className="flex justify-between items-center mb-4">
                <motion.h2
                  key={currentStep}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="text-xl font-bold"
                >
                  {steps[currentStep].title}
                </motion.h2>
                <button
                  onClick={handleClose}
                  className="text-gray-500 hover:text-gray-700 transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>

              <motion.p
                key={currentStep}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-gray-600 mb-6"
              >
                {steps[currentStep].content}
              </motion.p>

              <div className="flex justify-between items-center">
                <div className="flex gap-2">
                  {steps.map((_, index) => (
                    <motion.div
                      key={index}
                      initial={false}
                      animate={{
                        scale: index === currentStep ? 1.2 : 1,
                        backgroundColor:
                          index === currentStep ? '#7C3AED' : '#D1D5DB',
                      }}
                      className="w-2 h-2 rounded-full"
                    />
                  ))}
                </div>

                <div className="flex gap-2">
                  <button
                    onClick={handlePrevious}
                    disabled={currentStep === 0}
                    className={`px-3 py-2 rounded transition-colors ${
                      currentStep === 0
                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    Previous
                  </button>
                  <button
                    onClick={handleNext}
                    className="px-3 py-2 bg-violet-600 text-white rounded hover:bg-violet-700 transition-colors"
                  >
                    {currentStep === steps.length - 1 ? (
                      <span className="flex items-center gap-2">
                        <Check className="w-4 h-4" />
                        Complete
                      </span>
                    ) : (
                      'Next'
                    )}
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default RequestFieldsOnboarding
