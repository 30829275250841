// useImageProgressBar.ts
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "next/navigation";
import useMapStore from "@/common/store/map";

export const useImageProgressBar = (
  filteredGroupedData: any,
  onLoadAllImages: () => void,
  onCloseProgressBar: () => void,
  shouldOpen: boolean
) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { updateImageLayerOrder } = useMapStore();
  const searchParams = useSearchParams();

  const sortedDates = Object.keys(filteredGroupedData).sort((a, b) => {
    const dateA = filteredGroupedData[a].grdData[0].productName.split("_")[4];
    const dateB = filteredGroupedData[b].grdData[0].productName.split("_")[4];
    return dateA.localeCompare(dateB);
  });

  const totalImages = sortedDates.length;

  const updateImage = useCallback(() => {
    if (totalImages > 0) {
      const currentDate = sortedDates[currentIndex];
      const currentImage = filteredGroupedData[currentDate]?.grdData[0];
      updateImageLayerOrder(
        currentImage.firstFrame,
        currentImage.track,
        currentIndex
      );
    }
  }, [
    currentIndex,
    sortedDates,
    filteredGroupedData,
    updateImageLayerOrder,
    totalImages,
  ]);

  useEffect(() => {
    updateImage();
  }, [updateImage]);

  useEffect(() => {
    const firstFrame = searchParams.get("firstFrame");
    const track = searchParams.get("track");
    setIsPlaying(false);
    setCurrentIndex(0);
    setIsLoading(false);
  }, [searchParams]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isPlaying) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          if (prevIndex + 1 >= totalImages) {
            setIsPlaying(false);
            return prevIndex;
          }
          return prevIndex + 1;
        });
      }, 500);
    }
    return () => clearInterval(interval);
  }, [isPlaying, totalImages]);

  const handleIndexChange = (newIndex: number) => {
    setCurrentIndex(newIndex);
  };

  const toggleOpen = () => {
    if (shouldOpen) {
      onCloseProgressBar();
      setCurrentIndex(0);
      setIsPlaying(false);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      onLoadAllImages();
    }
  };

  const togglePlay = () => {
    setIsPlaying((prevIsPlaying) => {
      if (!prevIsPlaying && currentIndex === totalImages - 1) {
        setCurrentIndex(0);
      }
      return !prevIsPlaying;
    });
  };

  const currentDate = sortedDates[currentIndex];
  const currentImage = filteredGroupedData[currentDate]?.grdData[0];

  return {
    currentIndex,
    isPlaying,
    isLoading,
    totalImages,
    currentImage,
    handleIndexChange,
    toggleOpen,
    togglePlay,
  };
};
