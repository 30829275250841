// ImageProgressBarClosed.tsx
import { IconHistoryToggle, IconLoader2 } from '@tabler/icons-react'
import { motion } from 'framer-motion'

import useTooltip from '@/app/(main)/hooks/useTooltip'

type ImageProgressBarClosedProps = {
  isLoading: boolean
  toggleOpen: () => void
}

const ImageProgressBarClosed = ({
  isLoading,
  toggleOpen,
}: ImageProgressBarClosedProps) => {
  const { showTooltip, handleMouseEnter, handleMouseLeave } = useTooltip()

  return (
    <motion.div
      key="closed"
      transition={{ duration: 0.2 }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`relative p-2 bg-white rounded-md ${
        isLoading ? 'cursor-not-allowed opacity-90' : 'cursor-pointer'
      }`}
      onClick={!isLoading ? toggleOpen : undefined}
      whileHover={!isLoading ? { scale: 1.1 } : undefined}
      whileTap={!isLoading ? { scale: 0.9 } : undefined}
    >
      {!isLoading ? (
        <>
          <IconHistoryToggle size={24} />
          {showTooltip && (
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1 bg-gray-800 text-white text-xs rounded-md whitespace-nowrap">
              Load All Images
            </div>
          )}
        </>
      ) : (
        <IconLoader2 className={`animate-spin`} size={24} />
      )}
    </motion.div>
  )
}

export default ImageProgressBarClosed
