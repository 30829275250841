import { useEffect, useRef } from "react";

const useCanvas = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);
  const canvasOffSetX = useRef(0);
  const canvasOffSetY = useRef(0);
  const startX = useRef(0);
  const startY = useRef(0);
  const requestIdRef = useRef(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const updateCanvasSizeAndOffset = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      const canvasOffSet = canvas.getBoundingClientRect();
      canvasOffSetX.current = canvasOffSet.left;
      canvasOffSetY.current = canvasOffSet.top;
    };

    updateCanvasSizeAndOffset();
    const context = canvas.getContext("2d");
    if (context) {
      context.lineCap = "round";
      context.strokeStyle = "dodgerblue";
      context.lineWidth = 1;
      contextRef.current = context;
    }

    window.addEventListener("resize", updateCanvasSizeAndOffset);
    return () =>
      window.removeEventListener("resize", updateCanvasSizeAndOffset);
  }, []);

  return {
    canvasRef,
    contextRef,
    canvasOffSetX,
    canvasOffSetY,
    startX,
    startY,
    requestIdRef,
  };
};

export default useCanvas;
