import React from "react";
import { motion } from "framer-motion";

const backdrop = {
  hidden: { opacity: 0 },
  visible: { opacity: 0.8 },
};

const ProcessPopupBackground = () => {
  return (
    <motion.div
      className="fixed inset-0 z-[100] flex size-full items-center justify-center bg-black"
      initial="hidden"
      animate="visible"
      variants={backdrop}
      transition={{ duration: 0.8 }}
    />
  );
};

export default ProcessPopupBackground;
