import { SearchedDataPersistance } from '../repository/searched-data.repository'

import { DInSARImage } from '@/app/(main)/(home)/searched-data/(strategy)/dto/searched-data.dto'
import {
  ISearchedDataService,
  ToggleImage,
} from '@/app/(main)/(home)/searched-data/(strategy)/interfaces/searched-data.interface'

export class SearchedDataService implements ISearchedDataService {
  private persistance: SearchedDataPersistance

  constructor(private readonly accessToken: string | undefined) {
    this.persistance = new SearchedDataPersistance(accessToken)
  }

  async executeDInSARProcess(
    processingImages: ToggleImage[],
    userId: string,
    categoryId: string
  ) {
    const masterPosition = this._calculatePositionData(
      processingImages[0].polygon
    )
    const slavePosition = this._calculatePositionData(
      processingImages[1].polygon
    )

    const master: DInSARImage = {
      product_file_id: processingImages[0].title,
      downloadUrl: processingImages[0].downloadUrl,
      thumbnailUrl: processingImages[0].thumbnailUrl,
      ...masterPosition,
    }

    const slave: DInSARImage = {
      product_file_id: processingImages[1].title,
      downloadUrl: processingImages[1].downloadUrl,
      thumbnailUrl: processingImages[1].thumbnailUrl,
      ...slavePosition,
    }

    return await this.persistance.executeDInSARProcess(
      master,
      slave,
      userId,
      categoryId
    )
  }

  private _parsePolygon(polygonString: string): number[][] {
    const cleanedString = polygonString.replace(/^POLYGON\(\(|\)\)$/g, '')

    // Split the string into coordinate pairs and parse them
    return cleanedString
      .split(',')
      .map((coord) => coord.trim().split(' ').map(Number))
  }

  private _calculatePositionData(polygon: string[]) {
    const coordinates = polygon.flatMap((p) => this._parsePolygon(p))

    const lons = coordinates.map((coord) => coord[0])
    const lats = coordinates.map((coord) => coord[1])

    const minLon = Math.min(...lons)
    const maxLon = Math.max(...lons)
    const minLat = Math.min(...lats)
    const maxLat = Math.max(...lats)

    const centerLon = (minLon + maxLon) / 2
    const centerLat = (minLat + maxLat) / 2

    return {
      farEndLat: minLat,
      farEndLon: minLon,
      farStartLat: maxLat,
      farStartLon: maxLon,
      centerLat,
      centerLon,
    }
  }
}
