'use client'

import { usePathname } from 'next/navigation'
import React, { useEffect, useState } from 'react'

// import OnBoarding from './on-boarding'
import RequestFieldsOnBoarding from './request-fields-on-boarding'

const OnBoardingLayout = () => {
  const [showOnBoarding, setShowOnBoarding] = useState(false)
  const pathname = usePathname()

  useEffect(() => {
    // 로컬 스토리지를 확인하여 온보딩을 이미 완료했는지 확인
    const onBoardingCompleted = localStorage.getItem('onBoardingCompleted')
    if (!onBoardingCompleted) {
      setShowOnBoarding(true)
    }
  }, [])

  const handleOnBoardingComplete = () => {
    // 애니메이션 지속 시간을 300ms로 가정
    setTimeout(() => {
      setShowOnBoarding(false)
      localStorage.setItem('onBoardingCompleted', 'true')
    }, 300)
  }

  const isRequestFieldsPage = pathname?.startsWith('/request-fields')

  return (
    <>
      {showOnBoarding && isRequestFieldsPage && (
        <RequestFieldsOnBoarding onComplete={handleOnBoardingComplete} />
      )}
    </>
  )
}

export default OnBoardingLayout
