const API_BASE_URL = "/api/satellites";
const SEARCH = "search";
const SUBSCRIBE = "subscribe";
const USER = "user";
const SENTINEL = "sentinel";
const FRAME = "frame";
const PROCESSING = "processing";
const NOTIFICATION = "notification";
const INSAR = "insar";
const CACHE = "cache";
const IMAGE = "image";

export const API_ROUTES = {
  UPLOAD: {
    IMAGE: `${API_BASE_URL}/${IMAGE}/upload`,
  },
  SEARCH: {
    IMAGE: `${API_BASE_URL}/${SEARCH}/${IMAGE}`,
    PROCESSED_DATA: `${API_BASE_URL}/${SEARCH}/processed-data/category`,
    SENTINEL: {
      BASE: `${API_BASE_URL}/${SEARCH}/${SENTINEL}`,
      FRAME: `${API_BASE_URL}/${SEARCH}/${SENTINEL}/${FRAME}`,
    },
  },
  SUBSCRIBE: {
    USER: (userId: string) =>
      `${API_BASE_URL}/${SUBSCRIBE}/${USER}?user_id=${userId}`,
    USER_NOTIFICATION: {
      BY_FRAME: (userId: string) =>
        `${API_BASE_URL}/${SUBSCRIBE}/${USER}/${userId}/${NOTIFICATION}/${FRAME}`,
      BY_PROCESSING: (userId: string) =>
        `${API_BASE_URL}/${SUBSCRIBE}/${USER}/${userId}/${NOTIFICATION}/${PROCESSING}`,
      IS_READ: {
        BY_FRAME: `${API_BASE_URL}/${SUBSCRIBE}/${USER}/${NOTIFICATION}/${FRAME}/is-read`,
        BY_PROCESSING: `${API_BASE_URL}/${SUBSCRIBE}/${USER}/${NOTIFICATION}/${PROCESSING}/is-read`,
      },
    },
  },
  PROCESSING: `${PROCESSING}/${INSAR}`,
  CACHE: {
    GET: `${SEARCH}/${CACHE}/get/${IMAGE}`,
    SET: `${SEARCH}/${CACHE}/set/${IMAGE}`,
  },
};
