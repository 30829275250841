'use client'

import { selectionSidebarTabs } from '@repo/common/constants'
import { motion } from 'framer-motion'

interface SelectionSidebarTabsProps {
  activeTab: string
  setActiveTab: (value: string) => void
}

const SelectionSidebarTabs = ({
  activeTab,
  setActiveTab,
}: SelectionSidebarTabsProps) => {
  return (
    <div className="sidebar-width fixed z-10 inline-flex w-[384px] border bg-white pt-3 shadow-sm">
      <div className="flex-1 border-b border-gray-200">
        <nav className="-mb-px flex" aria-label="Tabs">
          {selectionSidebarTabs.map(({ icon, title, disabled }) => {
            return (
              <div
                key={title}
                className={`tab-link cursor-pointer ${activeTab === title ? 'tab-link-active' : ''} ${disabled ? 'cursor-not-allowed opacity-50' : ''}`}
                onClick={() => {
                  if (disabled) {
                    return
                  }
                  setActiveTab(title)
                }}
              >
                <motion.div
                  key={title}
                  whileHover={{ scale: disabled ? 1 : 1.05 }}
                  whileTap={{ scale: disabled ? 1 : 0.95 }}
                  className="mx-auto flex w-full items-center justify-center gap-2 pb-4"
                >
                  {icon}
                  {title}
                </motion.div>
              </div>
            )
          })}
        </nav>
      </div>
    </div>
  )
}

export default SelectionSidebarTabs
