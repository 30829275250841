// ProgressSlider.tsx
type ImageProgressSliderProps = {
  currentIndex: number;
  totalImages: number;
  handleIndexChange: (index: number) => void;
};

const ImageProgressSlider = ({
  currentIndex,
  totalImages,
  handleIndexChange,
}: ImageProgressSliderProps) => (
  <input
    type="range"
    min="0"
    max={totalImages - 1}
    value={currentIndex}
    onChange={(e) => handleIndexChange(parseInt(e.target.value))}
    className="w-[340px] h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500"
  />
);

export default ImageProgressSlider;
