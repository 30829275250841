'use client'

import { MAP_TILES } from '@repo/common/constants'
import Image from 'next/image'

import useMapStore from '@/common/store/map'

export default function TileLayerButtons() {
  const { currentTileLayer, setCurrentTileLayer } = useMapStore()
  // TODO: TileLayerButtons 사이즈 줄이기. (네이버 지도)

  return (
    <div
      className="fixed right-[2%] top-[2%] z-10 flex gap-[10px]"
      data-highlight="map-tile-layers"
    >
      {MAP_TILES.map((map) => {
        return (
          <div
            key={map.layerName}
            className={`ease box-border flex cursor-pointer flex-col items-center justify-between rounded-[3px] bg-white shadow-lg transition duration-300 hover:text-primary-500 ${
              currentTileLayer === map.layerName
                ? 'text-primary-500 ring-[2px] ring-violet-700'
                : 'ring-none'
            }`}
            onClick={() => {
              setCurrentTileLayer(map.layerName)
            }}
          >
            <Image
              src={`/assets/${map.layerName}.png`}
              alt={map.layerName}
              width={50}
              height={50}
              className="rounded-t-[3px]"
              role={map.layerName}
            />
            <span className="py-1 text-[10px] font-medium">{map.title}</span>
          </div>
        )
      })}
    </div>
  )
}
