"use client";

import SelectionSidebarTabs from "./SelectionSidebarTabs";
import DetailedImage from "./DetailedImage";
import dynamic from "next/dynamic";
import { selectionSidebarComponent } from "./SelectionSidebar.components";
import useSelectionSidebarFooter from "../hooks/useSelectionSidebarFooter";

const ProcessPossibility = dynamic(() => import("./ProcessPossibility"), {
  ssr: false,
});

const SelectionSidebarFooter = () => {
  const { activeTab, setActiveTab, filteredGroupedData, detailedImageData } =
    useSelectionSidebarFooter();

  // console.log(!filteredGroupedData);

  if (!filteredGroupedData) {
    return null;
  }

  return (
    <div className="flex h-[400px] flex-col">
      <SelectionSidebarTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === "Process" ? (
        <ProcessPossibility />
      ) : activeTab === "Related Files" ? (
        selectionSidebarComponent.RelatedFiles({
          filteredGroupedData,
          detailedImageData,
        })
      ) : (
        <DetailedImage />
      )}
    </div>
  );
};

export default SelectionSidebarFooter;
