'use client'

import { useGetSentinel } from '../../(home)/searched-data/(strategy)/hooks/useGetSentinel'
import { usePathname, useSearchParams } from 'next/navigation'
import React, { useEffect, useState } from 'react'

import ImageProgressBar from '@/app/(main)/components/image-progress/(strategy)/components/image-progress-bar'
import useSentinelImages from '@/app/(main)/hooks/useSentinelImages'

import useSentinelOption from '@/common/store/sentinel-option'
import useSidebarStore from '@/common/store/sidebar'

const ImageTimeLapse = () => {
  const { setIsSecondSidebarClosed } = useSidebarStore()
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const { selectedFirstFrame, selectedTrack } = useSentinelOption()

  const [areParamsValid, setAreParamsValid] = useState(false)

  useEffect(() => {
    const checkParams = () => {
      return Object.values(searchParams).every(
        (value) => value !== null && value !== undefined && value !== ''
      )
    }
    setAreParamsValid(checkParams())
  }, [searchParams])
  // console.log(areParamsValid);

  const { data } = useGetSentinel(searchParams)

  const {
    isAllImagesLoaded,
    shouldOpenProgressBar,
    removeOldImages,
    handleLoadAllImages,
    handleCloseProgressBar,
    setShouldOpenProgressBar,
    filteredGroupedData,
  } = useSentinelImages()

  useEffect(() => {
    if (data && data.length === 0) {
      setIsSecondSidebarClosed(true)
    }
  }, [data, setIsSecondSidebarClosed])

  useEffect(() => {
    if (data && data.length) {
      removeOldImages()
    }
  }, [data, removeOldImages])

  useEffect(() => {
    if (isAllImagesLoaded) {
      setShouldOpenProgressBar(true)
      // console.log("isAllImagesLoaded", true);
    }
  }, [isAllImagesLoaded, setShouldOpenProgressBar])

  useEffect(() => {
    removeOldImages()
  }, [selectedFirstFrame, selectedTrack, removeOldImages])

  if (!areParamsValid) {
    return null
  }

  if (!pathname.startsWith('/searched-data')) {
    return null
  }

  return (
    <ImageProgressBar
      shouldOpen={shouldOpenProgressBar}
      onCloseProgressBar={handleCloseProgressBar}
      onLoadAllImages={handleLoadAllImages}
      filteredGroupedData={filteredGroupedData}
    />
  )
}

export default ImageTimeLapse
