"use client";

import { usePathname } from "next/navigation";
import dynamic from "next/dynamic";

import { homeTabs, historyTabs, analyticsTabs } from "@repo/common/constants";

const IconTabs = dynamic(() => import("@/common/components/Tabs/IconTabs"), {
  ssr: false,
});

const SidebarTabs = () => {
  const pathname = usePathname();
  const isHistory = pathname.startsWith("/history");
  const isAnalytics = pathname.startsWith("/analytics");
  const tabItems = isHistory
    ? historyTabs
    : isAnalytics
      ? analyticsTabs
      : homeTabs;
  return <IconTabs justifyContent="justify-between" tabItems={tabItems} />;
};

export default SidebarTabs;
