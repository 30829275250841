'use client'

import EDSCTimeline from '@edsc/timeline'
import { motion } from 'framer-motion'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'

import useSidebarStore from '@/common/store/sidebar'
import {
  formatDateToYYYYMMDD,
  setSidebarXPosition,
  sidebarAnimation,
} from '@/utils/common'

const Timeline = () => {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { isSidebarClosed, isSecondSidebarClosed, setIsSidebarClosed } =
    useSidebarStore()

  const [temporalRange, setTemporalRange] = useState({
    start: new Date(searchParams.get('startDate')!).getTime(),
    end: new Date(searchParams.get('endDate')!).getTime(),
  })

  useEffect(() => {
    setTemporalRange({
      start: new Date(searchParams.get('startDate')!).getTime(),
      end: new Date(searchParams.get('endDate')!).getTime(),
    })
  }, [searchParams])

  return (
    <motion.div
      initial={sidebarAnimation.initial}
      animate={{
        x: setSidebarXPosition(isSidebarClosed, isSecondSidebarClosed),
      }}
      transition={sidebarAnimation.transition}
      className="absolute bottom-0 left-[453px] z-30 w-full"
      data-highlight="timeline"
    >
      <EDSCTimeline
        center={new Date().getTime() - 1000000000}
        data={[
          {
            id: 'row1',
            title: 'Temporal Selection',
            intervals: [],
          },
        ]}
        temporalRange={{
          start: temporalRange.start,
          end: temporalRange.end,
        }}
        onTemporalSet={(obj: { temporalStart: Date; temporalEnd: Date }) => {
          const currentDate = new Date()

          if (!obj.temporalStart || !obj.temporalEnd) {
            obj.temporalStart = new Date(currentDate - 1400000000)
            obj.temporalEnd = new Date(currentDate)
          }

          if (
            obj.temporalStart > currentDate ||
            obj.temporalEnd > currentDate
          ) {
            const newTemporalEnd = new Date(currentDate)
            const newTemporalStart = new Date(
              obj.temporalStart > currentDate ? currentDate : obj.temporalStart
            )

            const formattedStartDate = formatDateToYYYYMMDD(newTemporalStart)
            const formattedEndDate = formatDateToYYYYMMDD(newTemporalEnd)

            // ? URLSearchParams 객체를 업데이트
            const params = new URLSearchParams(searchParams.toString())
            params.set('startDate', formattedStartDate)
            params.set('endDate', formattedEndDate)

            // ? 페이지를 이동하지 않고 URL만 업데이트
            router.push(`${pathname}?${params.toString()}`, { scroll: false })

            setTemporalRange({
              start: new Date(searchParams.get('startDate')!).getTime(),
              end: new Date(searchParams.get('endDate')!).getTime(),
            })
          } else {
            const startDate = new Date(obj.temporalStart)
            const endDate = new Date(obj.temporalEnd)

            const formattedStartDate = formatDateToYYYYMMDD(startDate)
            const formattedEndDate = formatDateToYYYYMMDD(endDate)

            // ? 기존 쿼리스트링을 가져오고 새로운 쿼리스트링을 추가한다.
            const params = new URLSearchParams(searchParams.toString())
            params.set('startDate', formattedStartDate)
            params.set('endDate', formattedEndDate)

            // ? Timeline 날짜 업데이트
            setTemporalRange({
              start: startDate.getTime(),
              end: endDate.getTime(),
            })
            // ? URL 업데이트
            router.push(pathname + '?' + params.toString(), { scroll: false })

            setIsSidebarClosed(false)
          }
        }}
      />
    </motion.div>
  )
}

export default Timeline
