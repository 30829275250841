export function calculateAreaKM2(coordinates) {
  // 지구의 반지름 (km 단위)
  const R = 6371.0;

  // 라디안으로 변환하는 함수
  const toRadians = (degree) => (degree * Math.PI) / 180;

  // 주어진 좌표를 라디안으로 변환
  const lat1 = toRadians(coordinates.leftTop.lat);
  const lng1 = toRadians(coordinates.leftTop.lng);
  const lat2 = toRadians(coordinates.rightBottom.lat);
  const lng2 = toRadians(coordinates.rightBottom.lng);

  // 위도와 경도의 차이
  const dlat = lat2 - lat1;
  const dlng = lng2 - lng1;

  // 두 위도점 사이의 거리 계산
  const aLat =
    Math.sin(dlat / 2) ** 2 +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlng / 2) ** 2;
  const cLat = 2 * Math.atan2(Math.sqrt(aLat), Math.sqrt(1 - aLat));
  const distanceLat = R * cLat;

  // 평균 위도에서의 두 경도점 사이의 거리 계산
  const latMid = (lat1 + lat2) / 2;
  const aLng = Math.cos(latMid) * Math.sin(dlng / 2) ** 2;
  const cLng = 2 * Math.atan2(Math.sqrt(aLng), Math.sqrt(1 - aLng));
  const distanceLng = R * cLng;

  // 면적 계산 (km^2)
  const areaKM2 = distanceLat * distanceLng;
  return areaKM2;
}

export function calculateCostByKM2(areaKM2) {
  const costPer10KM2 = 0.005; // 10km^2당 비용
  const cost = (areaKM2 / 10) * costPer10KM2; // 전체 면적에 대한 비용 계산
  return cost;
}

export function calculateOverlapPercentage(rect1, rect2) {
  // 겹치는 영역의 좌표를 계산
  const overlapLeftTop = {
    lat: Math.min(rect1.leftTop.lat, rect2.leftTop.lat),
    lng: Math.max(rect1.leftTop.lng, rect2.leftTop.lng),
  };
  const overlapRightBottom = {
    lat: Math.max(rect1.rightBottom.lat, rect2.rightBottom.lat),
    lng: Math.min(rect1.rightBottom.lng, rect2.rightBottom.lng),
  };

  // 겹치는 영역이 없는 경우
  if (
    overlapLeftTop.lat < overlapRightBottom.lat ||
    overlapLeftTop.lng > overlapRightBottom.lng
  ) {
    return null;
  }

  // 겹치는 영역의 면적 계산
  const overlapArea =
    (overlapLeftTop.lat - overlapRightBottom.lat) *
    (overlapRightBottom.lng - overlapLeftTop.lng);

  // 두 직사각형의 면적 계산
  const area1 =
    (rect1.leftTop.lat - rect1.rightBottom.lat) *
    (rect1.rightBottom.lng - rect1.leftTop.lng);
  const area2 =
    (rect2.leftTop.lat - rect2.rightBottom.lat) *
    (rect2.rightBottom.lng - rect2.leftTop.lng);

  // 두 직사각형 중 작은 면적 찾기
  const smallerArea = Math.min(area1, area2);

  // 겹치는 영역의 백분율 계산
  const overlapPercentage = (overlapArea / smallerArea) * 100;

  return overlapPercentage;
}

export const findBounds = (coords) => {
  const leftTop = {
    lat: -Infinity,
    lng: Infinity,
  };
  const rightBottom = {
    lat: Infinity,
    lng: -Infinity,
  };

  coords.forEach(([lat, lng]) => {
    if (lat > leftTop.lat) leftTop.lat = lat;
    if (lng < leftTop.lng) leftTop.lng = lng;
    if (lat < rightBottom.lat) rightBottom.lat = lat;
    if (lng > rightBottom.lng) rightBottom.lng = lng;
  });

  return { leftTop, rightBottom };
};

export const getLatLng = ({ startX, startY, endX, endY }) => {
  const upperLeftLat = Math.max(startX, endX).toString();
  const lowerRightLat = Math.min(startX, endX).toString();
  const upperLeftLng = Math.min(startY, endY).toString();
  const lowerRightLng = Math.max(startY, endY).toString();

  return {
    upperLeftLat,
    lowerRightLat,
    upperLeftLng,
    lowerRightLng,
  };
};

export const getPolygonCoords = (image) =>
  image.map((coord) => {
    const [lon, lat] = coord
      .replace("POLYGON((", "")
      .replace("))", "")
      .split(" ")
      .map(Number);

    return [lat, lon];
  });
