import { TOAST_CONSTANTS } from '@repo/common/constants'
import {
  createToast,
  fitMapToRoiBounds,
  searchInitialData,
  stopDrawingRectangle,
} from '@repo/common/utils'
import * as L from 'leaflet'
import { useRouter, useSearchParams } from 'next/navigation'
import { MutableRefObject, useEffect, useState } from 'react'

import { ToastType } from '@/common/components/Toast/Toast'
import useMapStore from '@/common/store/map'
import useSidebarStore from '@/common/store/sidebar'

const useDrawing = (
  currentMap: L.Map,
  contextRef: MutableRefObject<CanvasRenderingContext2D>,
  canvasRef: MutableRefObject<HTMLCanvasElement>
) => {
  const [isDrawing, setIsDrawing] = useState(false) // 그리기 상태
  const [isDrawingEnd, setIsDrawingEnd] = useState(false) // 영역 선택 완료 상태
  const [roiPoints, setRoiPoints] = useState<{
    leftTop: L.LatLng | null
    rightBottom: L.LatLng | null
  } | null>(null)

  const { setROIEnable } = useMapStore()
  const { setIsSecondSidebarClosed: setSecondSidebarClose, setDataFetching } =
    useSidebarStore()
  const router = useRouter()
  const searchParams = useSearchParams()

  useEffect(() => {
    const handleMouseUp = async () => {
      if (!isDrawing) {
        return
      }

      document.body.style.cursor = 'wait' // 커서 대기 상태로 변경

      setROIEnable(false) // 영역 선택 중지
      setIsDrawingEnd(true) // 영역 선택 완료
      setDataFetching(true) // 데이터 조회 시작
      try {
        if (roiPoints) {
          await searchInitialData({ roiPoints, searchParams, router }) // 초기 데이터 조회
          fitMapToRoiBounds(roiPoints, currentMap) // 영역 맞춤
          createToast(ToastType.SUCCESS, TOAST_CONSTANTS.success.content) // 성공 토스트 띄우기
        }
      } catch (error) {
        console.error(error)
        createToast(ToastType.ERROR, TOAST_CONSTANTS.error.content) // 실패 토스트 띄우기
      } finally {
        document.body.style.cursor = 'auto' // 커서 자동 변경
        stopDrawingRectangle(contextRef, canvasRef) // 그리기 중지
        setDataFetching(false) // 데이터 조회 완료
        setIsDrawing(false) // 그리기 중지
        setIsDrawingEnd(false) // 영역 선택 중지
        setSecondSidebarClose(true) // 사이드바 닫기
      }
    }
    window.addEventListener('mouseup', handleMouseUp)

    return () => {
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [
    isDrawing,
    roiPoints,
    setROIEnable,
    setSecondSidebarClose,
    searchParams,
    router,
    currentMap,
    setDataFetching,
  ])

  return {
    isDrawing,
    isDrawingEnd,
    setIsDrawing,
    roiPoints,
    setRoiPoints,
  }
}

export default useDrawing
