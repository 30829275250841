import * as L from "leaflet";
import { getLatLng } from "../utils/leaflet";
import { ICalculatedBox, ISearchParam } from "../interfaces/request.interface";
import { SearchParamDto } from "../dto/searchParam.dto";
import { API_ROUTES } from "../constants/api-routes";
import { FLIGHT_DIRECTION } from "../enums";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

// ? ROI 그리기 완료 후 초기 데이터 조회 함수
export const searchInitialData = async ({
  roiPoints,
  searchParams,
  router,
}: {
  roiPoints: {
    leftTop: L.LatLng | null;
    rightBottom: L.LatLng | null;
  };
  searchParams: URLSearchParams;
  router: AppRouterInstance;
}) => {
  // ? ROI 좌표가 유효하지 않으면 함수 종료
  if (!roiPoints || !roiPoints.leftTop || !roiPoints.rightBottom) {
    throw new Error("ROI points are not valid.");
  }

  const calculatedBox: ICalculatedBox = getLatLng({
    startX: roiPoints.leftTop.lat,
    startY: roiPoints.leftTop.lng,
    endX: roiPoints.rightBottom.lat,
    endY: roiPoints.rightBottom.lng,
  });

  const formattedSearchParams: ISearchParam = {
    startDate: searchParams.get("startDate") ?? "",
    endDate: searchParams.get("endDate") ?? "",
    beamMode: searchParams.get("beamMode") ?? "",
    polarization: searchParams.get("polarization") ?? "",
    flightDirection:
      (searchParams.get("flightDirection") as FLIGHT_DIRECTION) ?? "",
  };

  const queryParams =
    new SearchParamDto().makeSearchParamDtoWithCalculatedBoxAndSearchParams(
      calculatedBox,
      formattedSearchParams,
      6,
      1
    );

  const fetchUrl = `${process.env.NEXT_PUBLIC_MAIN_SERVER_DOMAIN}${API_ROUTES.SEARCH.SENTINEL.BASE}?${queryParams}`;

  const response = await fetch(fetchUrl);
  const data = await response.json();

  if ((data && data.data.length === 0) || data.statusCode === 400) {
    throw new Error("No data found for the selected area.");
  } else {
    // 데이터가 있으면 router.push를 수행
    const params = new URLSearchParams(searchParams.toString());

    params.delete("startX");
    params.delete("startY");
    params.delete("endX");
    params.delete("endY");
    params.delete("upperLeftLat");
    params.delete("upperLeftLng");
    params.delete("lowerRightLat");
    params.delete("lowerRightLng");

    // router.push(
    //   `/searched-data?${params}&startX=${roiPoints.leftTop.lat}&startY=${roiPoints.leftTop.lng}&endX=${roiPoints.rightBottom.lat}&endY=${roiPoints.rightBottom.lng}&upperLeftLat=${roiPoints.leftTop.lat}&upperLeftLng=${roiPoints.leftTop.lng}&lowerRightLat=${roiPoints.rightBottom.lat}&lowerRightLng=${roiPoints.rightBottom.lng}`,
    //   {
    //     scroll: false,
    //   }
    // );
    router.push(
      `/searched-data?${params}&upperLeftLat=${Math.max(roiPoints.leftTop.lat, roiPoints.rightBottom.lat)}&upperLeftLng=${Math.min(roiPoints.leftTop.lng, roiPoints.rightBottom.lng)}&lowerRightLat=${Math.min(roiPoints.leftTop.lat, roiPoints.rightBottom.lat)}&lowerRightLng=${Math.max(roiPoints.leftTop.lng, roiPoints.rightBottom.lng)}`,
      {
        scroll: false,
      }
    );
  }
};

// ? ROI 그리기 완료 후 지도 뷰를 해당 ROI 경계에 맞게 조정하는 함수
export const fitMapToRoiBounds: (
  roiPoints: {
    leftTop: L.LatLng | null;
    rightBottom: L.LatLng | null;
  },
  currentMap: L.Map
) => void = (roiPoints, currentMap) => {
  if (roiPoints && roiPoints.leftTop && roiPoints.rightBottom && currentMap) {
    // ROI의 좌상단과 우하단 좌표를 사용하여 경계 객체 생성
    const bounds = L.latLngBounds([roiPoints.leftTop, roiPoints.rightBottom]);
    currentMap.fitBounds(bounds); // 지도의 뷰를 ROI의 경계에 맞게 조정
  }
};

export const convertCanvasPointToLatLng: (
  x: number,
  y: number,
  currentMap: L.Map,
  canvasOffSetX: React.MutableRefObject<number>,
  canvasOffSetY: React.MutableRefObject<number>
) => L.LatLng | null = (x, y, currentMap, canvasOffSetX, canvasOffSetY) => {
  try {
    if (!currentMap) {
      return null;
    }

    // ? 캔버스 상의 점을 지도 컨테이너 상의 점으로 변환
    const containerPoint = L.point(
      x + canvasOffSetX.current,
      y + canvasOffSetY.current
    );
    const latLng = currentMap.containerPointToLatLng(containerPoint);

    // console.log(containerPoint);

    // ? 경도 값이 180도를 초과하거나 -180도 미만일 경우 조정
    if (latLng.lng > 180) {
      latLng.lng -= 360;
    } else if (latLng.lng < -180) {
      latLng.lng += 360;
    }

    return latLng;
  } catch (error) {
    console.error(error);

    return null;
  }
};

export const startDrawingRectangle = (
  nativeEvent: MouseEvent,
  currentMap: L.Map,
  canvasOffSetX: React.MutableRefObject<number>,
  canvasOffSetY: React.MutableRefObject<number>,
  startX: React.MutableRefObject<number>,
  startY: React.MutableRefObject<number>,
  setIsDrawing: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    nativeEvent.preventDefault();
    nativeEvent.stopPropagation();

    const x = nativeEvent.clientX - canvasOffSetX.current;
    const y = nativeEvent.clientY - canvasOffSetY.current;

    const startPoint = convertCanvasPointToLatLng(
      x,
      y,
      currentMap,
      canvasOffSetX,
      canvasOffSetY
    );
    if (startPoint) {
      startX.current = x;
      startY.current = y;
    }

    // console.log("startDrawingRectangle 파라미터 값 logging : ", {
    //   x: nativeEvent.clientX,
    //   y: nativeEvent.clientY,
    //   canvasOffSetX: canvasOffSetX.current,
    //   canvasOffSetY: canvasOffSetY.current,
    //   startX: startX.current,
    //   startY: startY.current,
    // });

    setIsDrawing(true);
  } catch (error) {
    console.error(error);
  }
};

export const drawRectangle = (
  event: MouseEvent,
  isDrawing: boolean,
  currentMap: L.Map,
  canvasOffSetX: React.MutableRefObject<number>,
  canvasOffSetY: React.MutableRefObject<number>,
  startX: React.MutableRefObject<number>,
  startY: React.MutableRefObject<number>,
  contextRef: React.MutableRefObject<CanvasRenderingContext2D>,
  canvasRef: React.MutableRefObject<HTMLCanvasElement>,
  setRoiPoints: React.Dispatch<
    React.SetStateAction<{
      leftTop: L.LatLng;
      rightBottom: L.LatLng;
    }>
  >,
  requestIdRef: React.MutableRefObject<number>
) => {
  try {
    if (requestIdRef.current) {
      cancelAnimationFrame(requestIdRef.current);
    }

    requestIdRef.current = requestAnimationFrame(() =>
      throttledDrawRectangle(
        event,
        isDrawing,
        currentMap,
        canvasOffSetX,
        canvasOffSetY,
        startX,
        startY,
        contextRef,
        canvasRef,
        setRoiPoints,
        requestIdRef
      )
    );
  } catch (error) {
    console.error(error);
  }
};

export const stopDrawingRectangle = (
  contextRef: React.MutableRefObject<CanvasRenderingContext2D>,
  canvasRef: React.MutableRefObject<HTMLCanvasElement>
) => {
  // console.log("contextRef", contextRef, "canvasRef", canvasRef);
  if (contextRef.current && canvasRef.current) {
    contextRef.current.clearRect(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
  }
};

// ? 스로틀링 적용된 drawRectangle 함수를 생성
export const throttledDrawRectangle = (
  event: MouseEvent,
  isDrawing: boolean,
  currentMap: L.Map,
  canvasOffSetX: React.MutableRefObject<number>,
  canvasOffSetY: React.MutableRefObject<number>,
  startX: React.MutableRefObject<number>,
  startY: React.MutableRefObject<number>,
  contextRef: React.MutableRefObject<CanvasRenderingContext2D>,
  canvasRef: React.MutableRefObject<HTMLCanvasElement>,
  setRoiPoints: React.Dispatch<
    React.SetStateAction<{
      leftTop: L.LatLng;
      rightBottom: L.LatLng;
    }>
  >,
  requestIdRef: React.MutableRefObject<number>
) => {
  try {
    if (!isDrawing || !currentMap) {
      return;
    }

    // ? 이전에 요청된 애니메이션 프레임이 있다면 취소
    if (requestIdRef.current) {
      cancelAnimationFrame(requestIdRef.current);
    }

    event.preventDefault();
    event.stopPropagation();

    // ? 마우스 현재 위치를 캔버스 상의 좌표로 변환
    const mouseX = event.clientX - canvasOffSetX.current;
    const mouseY = event.clientY - canvasOffSetY.current;

    // ? 시작점과 마우스 현재 위치를 기반으로 사각형의 너비와 높이 계산
    const width = mouseX - startX.current;
    const height = mouseY - startY.current;

    // ? 시작점을 지도 상의 좌표로 변환
    const startLatLng = convertCanvasPointToLatLng(
      startX.current,
      startY.current,
      currentMap,
      canvasOffSetX,
      canvasOffSetY
    );
    // ? 마우스 현재 위치를 지도 상의 좌표로 변환
    const endLatLng = convertCanvasPointToLatLng(
      mouseX,
      mouseY,
      currentMap,
      canvasOffSetX,
      canvasOffSetY
    );

    // ? 시작점과 끝점이 유효하지 않으면 함수 종료
    if (!startLatLng || !endLatLng) {
      return;
    }

    // ? ROI Points 상태 업데이트
    setRoiPoints({
      leftTop: new L.LatLng(startLatLng.lat, startLatLng.lng),
      rightBottom: new L.LatLng(endLatLng.lat, endLatLng.lng),
    });

    // ? 캔버스를 지우고 새로운 사각형을 그림
    if (contextRef.current && canvasRef.current) {
      contextRef.current.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );

      // ? 사각형 UI
      contextRef.current.beginPath();
      contextRef.current.rect(startX.current, startY.current, width, height);
      contextRef.current.fillStyle = "rgba(0, 0, 255, 0.2)";
      contextRef.current.fill();
      contextRef.current.lineWidth = 1;
      contextRef.current.strokeStyle = "#539BFF";
      contextRef.current.stroke();
    }
  } catch (error) {
    console.error(error);
  }
};
