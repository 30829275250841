import { datasetItems } from '@repo/common/constants'
import { useSearchParams } from 'next/navigation'
import React from 'react'

const DatasetHeader = () => {
  const searchParams = useSearchParams()
  const menu = searchParams.get('menu')

  const selectedMenu = datasetItems.filter((item) => item.query === menu)
  return (
    <div className="sidebar-width fixed z-10 inline-flex w-[390px] bg-white pt-3 shadow-sm">
      <div className="flex-1 border-b-2 border-gray-200">
        <nav
          className="mb-2 flex items-center justify-center "
          aria-label="Tabs"
        >
          {selectedMenu.map((item, index) => {
            return (
              <div key={index} className="flex items-center gap-2">
                <span className={item.style}>{item.icon}</span>
                <span className="font-semibold">{item.text}</span>
              </div>
            )
          })}
        </nav>
      </div>
    </div>
  )
}

export default DatasetHeader
