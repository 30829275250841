import { IconCreditCard, IconInfoSquare, IconMapPin } from '@tabler/icons-react'
import React from 'react'

const withSize = (Icon: React.ComponentType<{ className?: string }>) => {
  return () => <Icon className="size-5" />
}

const processStatusText = [
  {
    title: 'Process Info.',
    icon: withSize(IconInfoSquare),
  },
  {
    title: 'Address',
    icon: withSize(IconMapPin),
  },
  {
    title: 'Payment',
    icon: withSize(IconCreditCard),
  },
]

const ProcessStatusBar = ({ currentPage }: { currentPage: number }) => {
  const getProgressWidth = (page: number) => {
    switch (page) {
      case 0:
        return '5%'
      case 1:
        return '50%'
      case 2:
        return '100%'
      default:
        return '0%'
    }
  }

  return (
    <div className="max-w-[770px] mx-auto mt-4">
      <h2 className="sr-only">Steps</h2>

      <div>
        <div className="overflow-hidden rounded-full bg-gray-200">
          <div
            className="h-2 rounded-full bg-blue-500 transition-all duration-300 ease-in-out"
            style={{ width: getProgressWidth(currentPage) }}
          ></div>
        </div>

        <ol className="mt-4 grid grid-cols-3 text-sm font-medium text-gray-500">
          {processStatusText.map((step, index) => (
            <li
              key={step.title}
              className={`flex items-center justify-${index === 0 ? 'start' : index === processStatusText.length - 1 ? 'end' : 'center'} ${
                index <= currentPage ? 'text-blue-600' : ''
              } sm:gap-1.5`}
            >
              <span className="hidden sm:inline"> {step.title} </span>

              <step.icon />
            </li>
          ))}
        </ol>
      </div>
    </div>
  )
}

export default ProcessStatusBar
