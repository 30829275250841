"use client";

import LoadingROI from "@/common/components/Loading/LoadingROI";
import DrawingCanvas from "./components/DrawingCanvas";
import useDrawing from "./hooks/useDrawing";
import useCanvas from "./hooks/useCanvas";
import {
  drawRectangle,
  startDrawingRectangle,
  stopDrawingRectangle,
} from "@repo/common/utils/roi";
import useMapStore from "@/common/store/map";
import * as L from "leaflet";

const DrawingROI = ({ currentMap }: { currentMap: L.Map }) => {
  const {
    canvasRef,
    contextRef,
    canvasOffSetX,
    canvasOffSetY,
    startX,
    startY,
    requestIdRef,
  } = useCanvas();

  const { isDrawing, isDrawingEnd, setIsDrawing, roiPoints, setRoiPoints } =
    useDrawing(currentMap, contextRef, canvasRef);
  const { isROIEnabled } = useMapStore();

  return (
    <>
      {isDrawingEnd && <LoadingROI />}
      <DrawingCanvas
        isROIEnabled={isROIEnabled}
        isDrawing={isDrawing}
        setIsDrawing={setIsDrawing}
        setRoiPoints={setRoiPoints}
        startDrawingRectangle={startDrawingRectangle}
        drawRectangle={drawRectangle}
        stopDrawingRectangle={stopDrawingRectangle}
        currentMap={currentMap}
        canvasOffSetX={canvasOffSetX}
        canvasOffSetY={canvasOffSetY}
        startX={startX}
        startY={startY}
        contextRef={contextRef}
        canvasRef={canvasRef}
        requestIdRef={requestIdRef}
      />
    </>
  );
};

export default DrawingROI;
