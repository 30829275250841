import { useCallback, useState } from "react";

const useTooltip = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setShowTooltip(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return {
    showTooltip,
    handleMouseEnter,
    handleMouseLeave,
  };
};

export default useTooltip;
