// ProgressInfo.tsx
type ImageProgressInfoProps = {
  currentIndex: number;
  totalImages: number;
};

const ImageProgressInfo = ({
  currentIndex,
  totalImages,
}: ImageProgressInfoProps) => (
  <div className="text-xs text-gray-500 mt-1 mr-2 text-right">
    {currentIndex + 1} / {totalImages}
  </div>
);

export default ImageProgressInfo;
