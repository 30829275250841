import { useImageCache } from '../hooks/useImageCache'
import { ProcessState } from '@repo/common/dto'
import { ApiResponse } from '@repo/common/interfaces'
import { Button } from '@repo/ui/components/ui/button'
import { IconShoppingCart } from '@tabler/icons-react'
import { ChevronLeft } from 'lucide-react'
import Image from 'next/image'
import React from 'react'

import { ToggleImage } from '@/app/(main)/(home)/searched-data/(strategy)/interfaces/searched-data.interface'

import ProcessPopupExecutionBtn from './ProcessPopupExecutionBtn'
import useMapStore from '@/common/store/map'
import useUserStore from '@/common/store/user'

const CartItem = ({ item }: { item: ToggleImage }) => {
  const { objectUrl, loading, error } = useImageCache(item.thumbnailUrl || '')

  return (
    <li className="flex w-full gap-4">
      <Image
        src={objectUrl || item.thumbnailUrl}
        width={100}
        height={100}
        alt={item.thumbnailUrl}
        className={`size-[100px] flex-shrink-0 rounded object-cover ${
          loading ? 'opacity-50' : 'opacity-100'
        }`}
      />

      <div className="flex flex-grow flex-col gap-2 overflow-hidden">
        <h3 className="break-words text-xs text-gray-900">
          {item?.downloadUrl?.match(/([^\/]+)(?=\.zip)/)?.[0]}
        </h3>

        <dl className="mt-0.5 space-y-px text-[10px] text-gray-600">
          <div>
            <dt className="inline">Platform: </dt>
            <dd className="inline">{item.tags[0]?.platform}</dd>
          </div>
        </dl>
      </div>
    </li>
  )
}

const ProcessPopupLastPhase = ({
  setCurrentPage,
  setOpenProcessPopup,
  executeDInSARProcess,
  currentFrameAndTrackImages,
}: {
  setCurrentPage: (page: number) => void
  setOpenProcessPopup: (open: boolean) => void
  executeDInSARProcess: (
    userId: string,
    categoryId: string
  ) => Promise<ApiResponse<ProcessState>>
  currentFrameAndTrackImages: ToggleImage[]
}) => {
  const { user, selectedCategory } = useUserStore()
  const { roiCost, setRoiCost, setRoiArea } = useMapStore()

  const subtotal = roiCost.toFixed(4)
  const vat = (roiCost / 10).toFixed(4)
  const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(4)

  const handlePrevious = () => {
    setCurrentPage(1)
  }

  return (
    <div className="relative flex h-full items-center justify-center">
      <div className="w-[500px]">
        {/* Header */}
        <div className="mb-6 flex items-center justify-start gap-2 font-bold text-lg">
          <IconShoppingCart size={20} />
          <span className="font-semibold">Order Summary</span>
          <span className="text-sm font-medium text-primary-500">
            (D-InSAR)
          </span>
        </div>

        {/* Content */}
        <div className="rounded-lg p-6">
          {/* Selected Images List */}
          <div className="mb-6 max-h-[300px] overflow-y-auto custom-scrollbar pr-2">
            <ul className="space-y-4">
              {currentFrameAndTrackImages.map((image, index) => (
                <CartItem key={index} item={image} />
              ))}
            </ul>
          </div>

          {/* Cost Summary */}
          <div className="space-y-4 border-t border-gray-200 pt-4">
            <dl className="space-y-2">
              <div className="flex justify-between text-sm text-gray-600">
                <dt>Subtotal</dt>
                <dd>${subtotal}</dd>
              </div>

              <div className="flex justify-between text-sm text-gray-600">
                <dt>VAT</dt>
                <dd>${vat}</dd>
              </div>

              <div className="flex justify-between text-sm text-gray-600">
                <dt>Discount</dt>
                <dd>$0</dd>
              </div>

              <div className="flex justify-between border-t border-gray-200 pt-4 text-base font-medium text-gray-900">
                <dt>Total</dt>
                <dd>${total}</dd>
              </div>
            </dl>

            {/* Execute Button */}
            <div className="pt-2">
              <ProcessPopupExecutionBtn
                user={user}
                selectedCategory={selectedCategory}
                setOpenProcessPopup={setOpenProcessPopup}
                executeDInSARProcess={executeDInSARProcess}
                setRoiCost={setRoiCost}
                setRoiArea={setRoiArea}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>

        {/* Previous Button */}
        <Button
          variant="default"
          className="absolute left-4 top-1/2 -translate-y-1/2 rounded-md p-4"
          onClick={handlePrevious}
        >
          <ChevronLeft className="h-6 w-6" />
        </Button>
      </div>
    </div>
  )
}

export default ProcessPopupLastPhase
