import { detailedCardComponent } from '../../RelatedTemporalData/components/detailed-card.component'
import { ISelectionSidebarService } from '../interface/SelectionSidebar.params'
import { SelectionSidebarService } from '../service/SelectionSidebar.service'

export class SelectionSidebarComponent {
  selectionSidebarService: ISelectionSidebarService

  constructor() {
    this.selectionSidebarService = new SelectionSidebarService()
  }

  Footer = () => {}

  ProcessPossibility = () => {}

  RelatedFiles = ({ filteredGroupedData, detailedImageData }) => {
    if (!detailedImageData || !filteredGroupedData[detailedImageData.groupID]) {
      return (
        <div className="mt-14 flex h-full items-center justify-center">
          Select your images
        </div>
      )
    }

    // console.log(
    //   filteredGroupedData[detailedImageData?.groupID],
    //   filteredGroupedData[detailedImageData.groupID],
    //   filteredGroupedData,
    //   detailedImageData
    // )

    return (
      <div className="custom-scrollbar mt-14 flex max-w-[384px] flex-col gap-2 overflow-y-scroll pb-4">
        {filteredGroupedData[detailedImageData.groupID].nonGrdData.map(
          (nongrdData) => (
            <div key={nongrdData.product_file_id}>
              {detailedCardComponent.NonGRDDataCard({
                data: nongrdData,
              })}
            </div>
          )
        )}
      </div>
    )
  }
}

export const selectionSidebarComponent = new SelectionSidebarComponent()
