import React, { ReactNode } from "react";

const SidebarHeader = ({ icon, title }: { icon: ReactNode; title: string }) => {
  return (
    <div className="mb-3 flex items-center gap-2 border-b pb-2">
      {icon}
      <span className="font-semibold">{title}</span>
    </div>
  );
};

export default SidebarHeader;
