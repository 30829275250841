import { useEffect } from "react";
import { usePathname } from "next/navigation";
import useSidebarStore from "@/common/store/sidebar";

const useSidebarPosition = () => {
  const pathname = usePathname();
  const { isSidebarClosed, isSecondSidebarClosed, setSecondSidebar } =
    useSidebarStore();

  const sidebarXPosition = () => {
    if (!isSecondSidebarClosed) {
      return 454; // Second sidebar open
    } else if (!isSidebarClosed) {
      return 0; // Only the first sidebar open
    } else {
      return -454; // Both sidebars closed
    }
  };

  useEffect(() => {
    if (pathname === "/searched-data/selection") {
      setSecondSidebar(false);
    }
    // eslint-disable-next-line
  }, [pathname]);

  return sidebarXPosition;
};

export default useSidebarPosition;
