// ImageProgressBarOpen.tsx
import { motion } from 'framer-motion'

import ImageInfo from './image-info'
import ImagePlayPauseButton from './image-play-pause-button'
import ImageProgressInfo from './image-progress-info'
import ImageProgressSlider from './image-progress-slider'

type ImageProgressBarOpenProps = {
  currentImage: any
  currentIndex: number
  totalImages: number
  isPlaying: boolean
  toggleOpen: () => void
  handleIndexChange: (index: number) => void
  togglePlay: () => void
}

const ImageProgressBarOpen = ({
  currentImage,
  currentIndex,
  totalImages,
  isPlaying,
  toggleOpen,
  handleIndexChange,
  togglePlay,
}: ImageProgressBarOpenProps) => {
  return (
    <motion.div key="open" className="bg-white p-2 rounded-md">
      <ImageInfo currentImage={currentImage} toggleOpen={toggleOpen} />
      <ImageProgressSlider
        currentIndex={currentIndex}
        totalImages={totalImages}
        handleIndexChange={handleIndexChange}
      />
      <div className="flex justify-between">
        <ImagePlayPauseButton isPlaying={isPlaying} togglePlay={togglePlay} />
        <ImageProgressInfo
          currentIndex={currentIndex}
          totalImages={totalImages}
        />
      </div>
    </motion.div>
  )
}

export default ImageProgressBarOpen
