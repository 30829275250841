import { NormalClose } from '@repo/ui/icons'
import React from 'react'

import useMapStore from '@/common/store/map'
import useProcessStore from '@/common/store/process'

const ProcessPopupHeaderCloseBtn = () => {
  const { setIsProcessROIEnable, setRoiArea, setRoiCost } = useMapStore()
  const { openProcessPopup, setOpenProcessPopup, currentPage, setCurrentPage } =
    useProcessStore()

  return (
    <button
      onClick={() => {
        setOpenProcessPopup(false)
        setCurrentPage(0)
        setIsProcessROIEnable(false)
        setRoiArea(0)
        setRoiCost(0)
      }}
      className="rounded-full p-1 transition duration-200 hover:bg-gray-300 active:bg-gray-400"
    >
      <NormalClose width="20" height="20" />
    </button>
  )
}

export default ProcessPopupHeaderCloseBtn
