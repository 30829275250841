import { IDetailedCardService } from '../interface/detailed-card.params'
import { DetailedCardService } from '../service/detailed-card.service'
import { Download } from '@repo/ui/icons'
import Image from 'next/image'

import Loader from '@/common/components/Loading/Loader'
import ImageNotFound from '@/common/components/not-found/image-not-found'

export class DetailedCardComponent {
  detailedDataCardService: IDetailedCardService

  constructor() {
    this.detailedDataCardService = new DetailedCardService()
  }

  ImageDownloadingLoader = () => (
    <div className="absolute bottom-1 right-1 flex gap-2 text-[10px] text-black">
      <Loader style={'size-4'} />
    </div>
  )

  ImageNotFound = () => (
    <div className="flex size-[60px] items-center justify-center rounded-lg border bg-gray-200">
      <ImageNotFound width={30} height={30} />
    </div>
  )

  TextArea = ({ data, dynamicTags }) => (
    <div className="flex flex-col justify-between">
      <span className="w-[260px] break-words text-[10px] font-medium text-black">
        {data.product_file_id || 'No Title'}
      </span>
      {this.Tags({ dynamicTags })}
    </div>
  )

  Tags = ({ dynamicTags }) => {
    return (
      <div className="flex w-[270px] items-end justify-start gap-1">
        {dynamicTags.map((tag) => {
          return (
            <span
              key={tag}
              className="rounded-md bg-gray-400 px-[6px] py-[2px] text-[8px] font-medium text-gray-100"
            >
              {tag}
            </span>
          )
        })}
      </div>
    )
  }

  NonGRDDataCard = ({ data }) => {
    // console.log(data);
    return (
      <div className="flex w-full cursor-pointer items-center gap-2 rounded-lg border bg-gray-100 px-1 shadow-lg transition duration-200 hover:bg-gray-200">
        <a
          href={data.downloadUrl}
          target="_blank"
          className="rounded-md p-1 transition duration-200 hover:bg-white"
        >
          <Download />
        </a>
        <div className="flex flex-col items-center justify-between gap-1 p-1">
          <span className="block w-[300px] overflow-hidden break-words text-[10px] font-medium text-black">
            {data.product_file_id || 'Unavailable Product Type'}
          </span>
        </div>
      </div>
    )
  }

  GRDDataCard = ({
    data,
    getImageBounds,
    dynamicTags,
    handleImageOverlay,
    isLoading,
    isSelected,
  }) => {
    // ? 선택된 카드라면 배경색을 hover 시와 동일하게 설정
    const cardClassName = `relative mx-auto min-w-[330px] cursor-pointer rounded-lg border py-1 pl-2 shadow-sm transition duration-200 ${
      isSelected ? 'bg-gray-200' : 'bg-white hover:bg-gray-200'
    }`

    return (
      <div
        className={cardClassName}
        onClick={async () => {
          await handleImageOverlay(data, getImageBounds)
        }}
      >
        <div className="flex items-center justify-between">
          <div className="flex h-[60px] gap-2">
            <div className="flex gap-1 flex-shrink-0">
              {isLoading && detailedCardComponent.ImageDownloadingLoader()}
              {data.thumbnailUrl ? (
                <Image
                  src={data.thumbnailUrl}
                  alt={data.productName}
                  width={60}
                  height={60}
                  loading="eager"
                  className="rounded-md bg-gray-300"
                />
              ) : (
                detailedCardComponent.ImageNotFound()
              )}
            </div>

            {detailedCardComponent.TextArea({ data, dynamicTags })}
          </div>
        </div>
      </div>
    )
  }

  ToggleableDataCard = ({ data }) => {}
}

export const detailedCardComponent = new DetailedCardComponent()
