'use client'

// 새로 만든 커스텀 훅을 import
import { Map as LeafletMap } from 'leaflet'

import { useMap } from '@/app/(main)/hooks/useMap'

import TileLayerButtons from '@/common/components/Buttons/TileLayerButtons'
import DrawingROI from '@/common/components/DrawingROI/DrawingROI'

const Map = () => {
  const { currentMap } = useMap()

  return (
    <>
      <div className="absolute top-0 z-10 size-full transition duration-300 ease-in">
        <div id="map" className="size-full" />
      </div>
      <DrawingROI currentMap={currentMap as LeafletMap} />
      <TileLayerButtons />
    </>
  )
}

export default Map
