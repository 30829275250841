// ImageInfo.tsx
import { motion } from "framer-motion";
import { IconTrashX } from "@tabler/icons-react";

type ImageInfoProps = {
  currentImage: any;
  toggleOpen: () => void;
};

const ImageInfo = ({ currentImage, toggleOpen }: ImageInfoProps) => (
  <div className="text-sm font-medium text-gray-700 mb-2 truncate flex justify-between items-center">
    <div className="flex flex-col text-sm">
      <span>{currentImage.productName.split("_").slice(0, 5).join("_")}</span>
      <span>{currentImage.productName.split("_").slice(5).join("_")}</span>
    </div>
    <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
      <IconTrashX size={20} className="cursor-pointer" onClick={toggleOpen} />
    </motion.div>
  </div>
);

export default ImageInfo;
