'use client'

import RectangleSkeleton from '../Skeleton/RectangleSkeleton'
import { CalendarNumber } from '@repo/ui/icons'
import { motion } from 'framer-motion'
import dynamic from 'next/dynamic'
import { usePathname } from 'next/navigation'
import React, { ReactNode, useEffect } from 'react'

import SelectionSidebar from '@/app/(main)/(home)/searched-data/(strategy)/components/SelectionSidebar/selection-sidebar'
import NewCalendar from '@/app/(main)/components/Calendar/(strategy)/components/NewCalendar'
import DatasetHeader from '@/app/(main)/dataset/(strategy)/components/dataset-header'

import SidebarTabs from './SidebarTabs'
import CloseSidebarButton from '@/common/components/Buttons/CloseSidebarButton'
import SidebarHeader from '@/common/components/Sidebar/(strategy)/components/SidebarHeader'
import SidebarSecondHeader from '@/common/components/Sidebar/(strategy)/components/SidebarSecondHeader'
import useSidebarStore from '@/common/store/sidebar'
import { PLATFORM_ROUTES } from '@/routes/app.routes'
import { sidebarAnimation } from '@/utils/common'

const SearchButton = dynamic(
  () => import('@/common/components/Buttons/SearchButton'),
  {
    ssr: false,
  }
)

const SidebarTabContents = ({ template }: { template: ReactNode }) => {
  const pathname = usePathname()

  const {
    isSidebarClosed,
    isSecondSidebarClosed,
    toggleSidebarClosed,
    dataFetching,
    setDataFetching,
  } = useSidebarStore()

  useEffect(() => {
    if (dataFetching) {
      setDataFetching(false)
    }

    // eslint-disable-next-line
  }, [pathname, template])
  return (
    <>
      <motion.div
        initial={sidebarAnimation.initial}
        animate={sidebarAnimation.animate(isSidebarClosed)}
        transition={sidebarAnimation.transition}
        className={`fixed left-[64px] top-0 z-30 flex h-screen w-[390px] flex-1 flex-col border-e bg-gray-100 ${
          !isSecondSidebarClosed ? 'shadow-none' : 'shadow-lg'
        }`}
        data-testid="sidebar"
      >
        {!pathname.startsWith(PLATFORM_ROUTES.DATASET.href) ? (
          <SidebarTabs />
        ) : (
          <DatasetHeader />
        )}
        <div className=" flex h-screen flex-1 flex-col pt-16">
          {/* // ? Data Collections */}
          {dataFetching ? (
            <div className="px-4">
              <SidebarHeader
                icon={<CalendarNumber />}
                title="Temporal Selection"
              />
              <NewCalendar />

              <SidebarSecondHeader />
              <RectangleSkeleton rows={4} height={70} />
            </div>
          ) : (
            template
          )}
        </div>
      </motion.div>

      <SearchButton />

      <CloseSidebarButton
        isSidebarClosed={isSidebarClosed}
        toggleSidebarClosed={toggleSidebarClosed}
        position={'left-[454px] z-20'}
      />

      <SelectionSidebar />
    </>
  )
}

export default SidebarTabContents
