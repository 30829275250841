'use client'

import * as L from 'leaflet'
import React from 'react'

const DrawingCanvas = ({
  isROIEnabled,
  isDrawing,
  setIsDrawing,
  setRoiPoints,
  startDrawingRectangle,
  drawRectangle,
  stopDrawingRectangle,
  currentMap,
  canvasOffSetX,
  canvasOffSetY,
  startX,
  startY,
  contextRef,
  canvasRef,
  requestIdRef,
}: {
  isROIEnabled: boolean
  isDrawing: boolean
  setIsDrawing: (isDrawing: boolean) => void
  setRoiPoints: (
    roiPoints: { leftTop: L.LatLng | null; rightBottom: L.LatLng | null } | null
  ) => void
  startDrawingRectangle: (
    e: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    currentMap: string,
    canvasOffSetX: number,
    canvasOffSetY: number,
    startX: number,
    startY: number,
    setIsDrawing: (isDrawing: boolean) => void
  ) => void
  drawRectangle: (
    e: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    isDrawing: boolean,
    currentMap: string,
    canvasOffSetX: number,
    canvasOffSetY: number,
    startX: number,
    startY: number,
    contextRef: React.RefObject<CanvasRenderingContext2D>,
    canvasRef: React.RefObject<HTMLCanvasElement>,
    setRoiPoints: (
      roiPoints: {
        leftTop: L.LatLng | null
        rightBottom: L.LatLng | null
      } | null
    ) => void,
    requestIdRef: React.RefObject<number>
  ) => void
  stopDrawingRectangle: (
    contextRef: React.RefObject<CanvasRenderingContext2D>,
    canvasRef: React.RefObject<HTMLCanvasElement>
  ) => void
  currentMap: L.Map
  canvasOffSetX: React.MutableRefObject<number>
  canvasOffSetY: React.MutableRefObject<number>
  startX: React.MutableRefObject<number>
  startY: React.MutableRefObject<number>
  contextRef: React.RefObject<CanvasRenderingContext2D>
  canvasRef: React.RefObject<HTMLCanvasElement>
  requestIdRef: React.RefObject<number>
}) => {
  return (
    <canvas
      ref={canvasRef}
      onMouseDown={(e) =>
        startDrawingRectangle(
          e,
          currentMap,
          canvasOffSetX,
          canvasOffSetY,
          startX,
          startY,
          setIsDrawing
        )
      }
      onMouseMove={(e) =>
        drawRectangle(
          e,
          isDrawing,
          currentMap,
          canvasOffSetX,
          canvasOffSetY,
          startX,
          startY,
          contextRef,
          canvasRef,
          setRoiPoints,
          requestIdRef
        )
      }
      onMouseUp={() => stopDrawingRectangle(contextRef, canvasRef)}
      onMouseLeave={() => stopDrawingRectangle(contextRef, canvasRef)}
      style={{ display: isROIEnabled ? '' : 'none' }}
      className="fixed top-0 z-20 size-full"
    ></canvas>
  )
}

export default DrawingCanvas
