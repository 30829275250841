import { Category, ProcessState } from '@repo/common/dto'
import { ApiInnerResponse, ApiResponse } from '@repo/common/interfaces'
import { MyDataService } from '@repo/common/services'
import { Button } from '@repo/ui/components/ui/button'
import { IconFolder } from '@tabler/icons-react'
import Cookies from 'js-cookie'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import React, { useEffect, useState } from 'react'

import useUserStore from '@/common/store/user'

type ProcessPopupLastPhaseProps = {
  setCurrentPage: (page: number) => void
  setOpenProcessPopup: (open: boolean) => void
  executeDInSARProcess: (
    userId: string,
    categoryId: string
  ) => Promise<ApiResponse<ProcessState>>
}

const currentProcessName = 'D-InSAR'

const ProcessPopupSecondPhase = ({
  setCurrentPage,
  setOpenProcessPopup,
  executeDInSARProcess,
}: ProcessPopupLastPhaseProps) => {
  const accessToken = Cookies.get('accessToken')
  const myDataService = new MyDataService(accessToken)
  const { user, selectedCategory, setSelectedCategory } = useUserStore()
  const [categories, setCategories] = useState<Category[]>([])

  useEffect(() => {
    const fetchMyCategories = async () => {
      try {
        const response = await myDataService.getAllCategories()
        if (response.status === 200) {
          setCategories(response.data.items)
        } else {
          throw new Error('Failed to fetch my categories')
        }
      } catch (error) {
        console.error(
          'ProcessPopup:Error fetching my categories:',
          error.message
        )
      }
    }

    fetchMyCategories()
  }, [])

  const handlePrevious = () => {
    setCurrentPage(0)
  }

  const handleNext = () => {
    setCurrentPage(2)
  }

  return (
    <div className="relative flex h-full items-center justify-center">
      <div className="w-[500px]">
        {/* Header */}
        <div className="mb-6 flex items-center gap-2 font-bold text-lg">
          <IconFolder size={20} />
          <span className="font-semibold">Select Process Folder</span>
          <span className="text-sm font-medium text-primary-500">
            ({currentProcessName})
          </span>
        </div>

        {/* Categories Grid */}
        <div className="rounded-lg bg-gray-50 p-4 shadow-inner">
          <div className="grid grid-cols-2 gap-3 max-h-[400px] overflow-y-auto custom-scrollbar">
            {categories.length === 0 && (
              <div className="flex h-20 items-center justify-center rounded-md p-3 text-center text-sm font-medium">
                Checking your categories...
              </div>
            )}
            {categories.map((category: Category) => (
              <div
                key={category.categoryId}
                className={`flex h-20 cursor-pointer items-center justify-center rounded-md p-3 text-center text-sm font-medium shadow-md transition-all duration-200 ease-in-out
                  ${
                    selectedCategory?.categoryId === category.categoryId
                      ? 'bg-gray-200 font-bold'
                      : 'bg-white hover:bg-gray-100'
                  }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category.name}
              </div>
            ))}
          </div>
        </div>

        {/* Navigation Buttons */}
        <Button
          variant="default"
          className="absolute left-4 top-1/2 -translate-y-1/2 rounded-md p-4"
          onClick={handlePrevious}
        >
          <ChevronLeft className="h-6 w-6" />
        </Button>

        <Button
          variant="default"
          className="absolute right-4 top-1/2 -translate-y-1/2 rounded-md p-4"
          onClick={handleNext}
        >
          <ChevronRight className="h-6 w-6" />
        </Button>
      </div>
    </div>
  )
}

export default ProcessPopupSecondPhase
