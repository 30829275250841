import { motion } from 'framer-motion'
import React from 'react'

const popup = {
  hidden: { scale: 0.5, opacity: 0, x: '-50%', y: '-50%' },
  visible: {
    scale: 1,
    opacity: 1,
    x: '-50%',
    y: '-50%',
    transition: {
      type: 'spring',
      stiffness: 260,
      damping: 20,
    },
  },
}

const ProcessPopupContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <motion.div
      className="fixed left-1/2 top-1/2 z-[200] h-[90%] w-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-md bg-white px-10 py-4 shadow-lg"
      initial="hidden"
      animate="visible"
      variants={popup}
      transition={{ duration: 0.3 }}
    >
      {children}
    </motion.div>
  )
}

export default ProcessPopupContainer
