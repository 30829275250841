import { useImageProgressBar } from '../hooks/useImageProgressBar'
import { AnimatePresence, motion } from 'framer-motion'

import ImageProgressBarClosed from './image-progress-bar-closed'
import ImageProgressBarOpen from './image-progress-bar-open'

type ImageProgressBarProps = {
  filteredGroupedData: any
  onLoadAllImages: () => void
  onCloseProgressBar: () => void
  shouldOpen: boolean
}

const ImageProgressBar = ({
  filteredGroupedData,
  onLoadAllImages,
  onCloseProgressBar,
  shouldOpen,
}: ImageProgressBarProps) => {
  const {
    currentIndex,
    isPlaying,
    isLoading,
    totalImages,
    currentImage,
    handleIndexChange,
    toggleOpen,
    togglePlay,
  } = useImageProgressBar(
    filteredGroupedData,
    onLoadAllImages,
    onCloseProgressBar,
    shouldOpen
  )

  if (totalImages === 0) {
    return null
  }

  return (
    <motion.div
      className="fixed bottom-[80px] right-[20px] p-2 rounded-md z-30"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <AnimatePresence mode="wait">
        {shouldOpen ? (
          <ImageProgressBarOpen
            currentImage={currentImage}
            currentIndex={currentIndex}
            totalImages={totalImages}
            isPlaying={isPlaying}
            toggleOpen={toggleOpen}
            handleIndexChange={handleIndexChange}
            togglePlay={togglePlay}
          />
        ) : (
          <ImageProgressBarClosed
            isLoading={isLoading}
            toggleOpen={toggleOpen}
          />
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default ImageProgressBar
