'use client'

import { IconX } from '@tabler/icons-react'
import { motion } from 'framer-motion'
import dynamic from 'next/dynamic'
import React from 'react'

import SelectionSidebarFooter from './components/SelectionSidebarFooter'
import useSidebarPosition from './hooks/useSidebarPosition'
import RectangleSkeleton from '@/common/components/Skeleton/RectangleSkeleton'
// import { useSatelliteSearchParams } from '@/common/hooks/useCustomSearchParams'
import useSidebarStore from '@/common/store/sidebar'
import { sidebarAnimation } from '@/utils/common'

const RelatedTemporalData = dynamic(
  () => {
    return import(
      '@/app/(main)/(home)/searched-data/(strategy)/components/RelatedTemporalData/related-temporal-data'
    )
  },
  {
    loading: () => <RectangleSkeleton rows={4} height={70} />,
    ssr: false,
  }
)

const SelectionSidebar = () => {
  // const searchParams = useSatelliteSearchParams();

  const sidebarXPosition = useSidebarPosition()
  const { isSecondSidebarClosed, toggleSecondSidebarClosed } = useSidebarStore()

  return (
    <motion.div
      initial={false}
      animate={{ x: sidebarXPosition() }}
      transition={sidebarAnimation.transition}
      className="fixed top-0 z-20 flex h-screen w-96 flex-col justify-between bg-gray-100"
    >
      <header className="border-b-[1px] border-transparent bg-white flex items-end justify-between pl-4 py-3 shadow-md">
        <div className="flex items-center gap-2">
          {/*<Photo />*/}
          <h1 className="font-semibold">Related Temporal Images</h1>
        </div>
      </header>
      <div className="custom-scrollbar flex flex-1 flex-col overflow-auto overflow-x-hidden px-4 py-2 pb-4 gap-1">
        <RelatedTemporalData />
      </div>
      <SelectionSidebarFooter />
      <button
        className={`absolute left-[384px] top-[10px] z-20 rounded-r-sm bg-primary p-2 text-gray-100 delay-300  ${
          isSecondSidebarClosed ? 'hidden' : ''
        }`}
        onClick={toggleSecondSidebarClosed}
      >
        <IconX size={24} />
      </button>
    </motion.div>
  )
}

export default SelectionSidebar
