import { useGetSentinel } from '../../../hooks/useGetSentinel'
import { selectionSidebarTabs } from '@repo/common/constants'
// import { SearchParamDto } from '@repo/common/dto'
// import { ICalculatedBox } from '@repo/common/interfaces'
import { satelliteService } from '@repo/common/services'
import { useSearchParams } from 'next/navigation'
import { useState } from 'react'

// import useSentinel from '@/app/(main)/(home)/searched-data/(strategy)/hooks/useSentinel'
import useMapStore from '@/common/store/map'
import useSentinelOption from '@/common/store/sentinel-option'
import { Sentinel } from '@/core/entities/models/sentinel'
import { getFirstFrameAndTrack } from '@/utils/common'

const useSelectionSidebarFooter = () => {
  const [activeTab, setActiveTab] = useState(selectionSidebarTabs[0].title)
  const searchParams = useSearchParams()

  const { selectedFirstFrame, selectedTrack } = useSentinelOption()

  // const calculatedBox: ICalculatedBox = satelliteService.getLatLng(
  //   Object.fromEntries(searchParams)
  // )
  const { selectedImages } = useMapStore()

  const selectedImagesByFrame =
    selectedImages[getFirstFrameAndTrack(selectedFirstFrame, selectedTrack)] ||
    []

  const detailedImageData =
    selectedImagesByFrame[selectedImagesByFrame.length - 1]
  // const queryParams =
  //   new SearchParamDto().makeSearchParamDtoWithCalculatedBoxAndSearchParams(
  //     calculatedBox,
  //     Object.fromEntries(searchParams)
  //   )

  const { data, isLoading } = useGetSentinel(searchParams)

  // const { data, isLoading } = useSentinel(queryParams)
  // console.log(data)
  const groupedData = satelliteService.groupedData(data || [])
  // console.log(groupedData);

  const filteredGroupedData = groupedData
    ? Object.entries(groupedData).reduce(
        (
          acc: Record<string, { grdData: Sentinel[]; nonGrdData: Sentinel[] }>,
          [date, dataGroup]
        ) => {
          if (
            dataGroup.grdData[0].firstFrame === +selectedFirstFrame &&
            dataGroup.grdData[0].track === +selectedTrack
          ) {
            acc[date] = dataGroup
          }
          return acc
        },
        {}
      )
    : null

  // console.log(filteredGroupedData);

  return {
    activeTab,
    setActiveTab,
    filteredGroupedData,
    detailedImageData,
    isLoading,
  }
}

export default useSelectionSidebarFooter
