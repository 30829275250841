// PlayPauseButton.tsx
import { motion } from "framer-motion";
import { IconPlayerPause, IconPlayerPlay } from "@tabler/icons-react";

type ImagePlayPauseButtonProps = {
  isPlaying: boolean;
  togglePlay: () => void;
};

const ImagePlayPauseButton = ({
  isPlaying,
  togglePlay,
}: ImagePlayPauseButtonProps) => (
  <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
    {isPlaying ? (
      <IconPlayerPause
        size={20}
        className="cursor-pointer"
        onClick={togglePlay}
      />
    ) : (
      <IconPlayerPlay
        size={20}
        className="cursor-pointer"
        onClick={togglePlay}
      />
    )}
  </motion.div>
);

export default ImagePlayPauseButton;
