import { Category, ProcessState } from '@repo/common/dto'
import { ApiResponse } from '@repo/common/interfaces'
import { Button } from '@repo/ui/components/ui/button'
import { IconLoader2 } from '@tabler/icons-react'
import React, { useState } from 'react'

import { ToastType } from '@/common/components/Toast/Toast'
import { createToast } from '@/utils/createToast'

type ProcessPopupExecutionBtnProps = {
  user: {
    image: string
    name: string
    email: string
    userId: string
  } | null
  selectedCategory: Category | null
  setOpenProcessPopup: (open: boolean) => void
  executeDInSARProcess: (
    userId: string,
    categoryId: string
  ) => Promise<ApiResponse<ProcessState>>
  setRoiCost: (roiCost: number) => void
  setRoiArea: (roiArea: number) => void
  setCurrentPage: (currentPage: number) => void
}

const ProcessPopupExecutionBtn = ({
  user,
  selectedCategory,
  setOpenProcessPopup,
  executeDInSARProcess,
  setRoiCost,
  setRoiArea,
  setCurrentPage,
}: ProcessPopupExecutionBtnProps) => {
  const [loading, setLoading] = useState(false)

  return (
    <Button
      className="flex gap-2 w-full"
      onClick={async () => {
        setLoading(true)
        try {
          const response = await executeDInSARProcess(
            user?.userId || '',
            selectedCategory?.categoryId || ''
          )
          // console.log(response);

          if (response.status === 201) {
            createToast(
              ToastType.SUCCESS,
              'Process has been started successfully!'
            )
            setRoiCost(0)
            setRoiArea(0)
            setCurrentPage(0)
            setOpenProcessPopup(false)
          } else {
            createToast(
              ToastType.ERROR,
              'Failed to start the process. Please try again.'
            )
          }
        } catch (error) {
          console.error('Error executing DInSAR process:', error)
          createToast(
            ToastType.ERROR,
            'An unexpected error occurred. Please try again.'
          )
        } finally {
          setLoading(false)
        }
      }}
      disabled={loading}
    >
      {loading ? (
        <IconLoader2 size={20} className="animate-spin" />
      ) : (
        'Checkout'
      )}
    </Button>
  )
}

export default ProcessPopupExecutionBtn
